import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Stack,
  TextInput,
  Select,
  Group,
  Loader,
  Text,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../../Helpers/Axios";
import CustomFileInput from "./CustomFileInput";
import styled from "styled-components";
import { showNotification } from "@mantine/notifications";
import { useMediaQuery } from '@mantine/hooks';

import {
  Check,
  ChevronRight,
  Confetti,
  Download,
  Edit,
  Plus,
  Trash,
  X,
} from "tabler-icons-react";

// Validation schema for user information
const userInfoSchema = yup.object().shape({
  first_name: yup.string().required("Name is required"),
  last_name: yup.string().required("Surname is required"),
  number: yup.string().required("Mobile number is required"),
  address: yup.string().required("Address is required"),
});



const UserInfoStep = ({ props }) => {
  const [showAddButton, setShowAddButton] = useState(false);
  const [editingId, setEditingId] = useState(null); // New state for editing
  const queryClient = useQueryClient();
  const fileInputRef = useRef();
  const {t, setStep, handleLanguageCertificationSubmit, user, setIsUserChanged } =
    props;
  const initialUserInfo = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    number: user?.number,
    address: user?.address,
  };

  const {
    register: userRegister,
    handleSubmit: handleUserSubmit,
    getValues: getUserValues,
    reset: resetUserForm,
    formState: { errors: userErrors },
  } = useForm({
    defaultValues: initialUserInfo,
    resolver: yupResolver(userInfoSchema),
  });

  // Form for certificate information
  const {
    register: certRegister,
    handleSubmit: handleCertSubmit,
    setValue: setCertValue,
  } = useForm();

  const { data: certificationTypes } = useQuery({
    queryKey: ["certification-types"],
    queryFn: async () => {
      return await axiosInstance.get(`/api/skill/endorsed/`).then((res) => res.data);
    },
  });

  const { data: userCertifications } = useQuery({
    queryKey: ["user-certifications"],
    queryFn: async () => {
      return await axiosInstance.get(`api/userskill/endorsed/`).then((res) => res.data);
    },
  });

  const [selectedSkill, setSelectedSkill] = useState("");
  const [pointValue, setPointValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const { mutate: createCertificate, isLoading: isCreateCertificateLoading } =
    useMutation({
      mutationFn: async (data) => {
        return await axiosInstance.post(`/api/create-userskill/`, data);
      },
      onSuccess: (data) => {
        showNotification({
          icon: <Confetti />,
          title: t('success'),
          message: t('certificates-ntm1'),
          color: "teal",
        });
        queryClient.invalidateQueries(["user-certifications"]);
        setSelectedSkill("");
        setPointValue("");
        setSelectedFile(null);
        setCertValue("skill", "");
        setCertValue("point", "");
        setCertValue("certificate", null);
        setShowAddButton(false);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const { mutate: editCertificate } =
    useMutation({
      mutationFn: async (data) => {
        return await axiosInstance.patch(`/api/userskill/${editingId}/`, data);
      },
      onSuccess: (data) => {
        showNotification({
          icon: <Confetti />,
          title: t('success'),
          message: t('certificates-ntm2'),
          color: "teal",
        });
        setEditingId(null);
        queryClient.invalidateQueries(["user-certifications"]);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const { mutate: deleteCertificate } =
    useMutation({
      mutationFn: async (data) => {
        return await axiosInstance
          .delete(`/api/userskill/${data}/`)
          .then((res) => res.data);
      },
      onSuccess: (data) => {
        showNotification({
          icon: <Confetti />,
          title: t('success'),
          message: t('certificates-ntm3'),
          color: "teal",
        });
        queryClient.invalidateQueries(["user-certifications"]);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const { mutate: editUser, isLoading: isEditUserLoading } = useMutation({
    mutationFn: async (data) => {
      return await axiosInstance.patch(`/api/v1/edit-user/${user?.id}/`, data);
    },
    onSuccess: ({ data }) => {
      setStep("admission");
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setIsUserChanged(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleUserFormSubmit = (data) => {
    const currentUserValues = getUserValues();
    const hasChanged = Object.keys(initialUserInfo).some(
      (key) => initialUserInfo[key] !== currentUserValues[key]
    );
    if (userCertifications && userCertifications.length === 0) {
      showNotification({
        icon: <X />,
        title: t('warning'),
        message: t('second_cert_warning'),
        color: "yellow",
      });
    } else if (hasChanged) {
      editUser(data);
    } else {
      setStep("admission");
    }
  };

  const handleCertFormSubmit = (data) => {
    const certificatedFormData = new FormData();
    certificatedFormData.append("user", user?.id);

    if (editingId === null) {
      if (data.skill && data.point && data.certificate) {
        certificatedFormData.append("skill", data.skill);
        certificatedFormData.append("certificate", data.certificate);
        certificatedFormData.append("point", data.point);
        createCertificate(certificatedFormData);
        // Reset form fields

      } else {
        showNotification({
          icon: <Confetti />,
          title: t('warning'),
          message: t('cert_warning'),
          color: "teal",
        });
      }
    } else {
      certificatedFormData.append("id", editingId);
      data.certificate &&
        certificatedFormData.append("certificate", data.certificate);
      data.point && certificatedFormData.append("point", data.point);
      editCertificate(certificatedFormData);
    }
  };

  const handleHiddenFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCertValue("certificate", file);
    }
  };

  const handleButtonClick = (certificationId) => {
    setEditingId(certificationId);
    fileInputRef?.current?.click();
  };

  useEffect(() => {
    userCertifications && handleLanguageCertificationSubmit(userCertifications);
  }, [userCertifications]);

  useEffect(() => {
    if (Object.keys(userErrors).length !== 0) {
      showNotification({
        icon: <X />,
        title: t('error'),
        message: t('fields_warning'),
        color: "red",
      });
    }
  }, [userErrors]);

  useEffect(() => {
    if (user) {
      resetUserForm({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        number: user?.number,
        address: user?.address,
      });
    }
  }, [user]);
  const xs = useMediaQuery('(max-width: 900px)');


  return (
    <form onSubmit={handleUserSubmit(handleUserFormSubmit)}>
      <Stack>
        <Grid>
          <Grid.Col sm={6}>
            <TextInput
              radius={10}
              size="lg"
              placeholder={t("name")}
              label={t("name")}
              withAsterisk
              {...userRegister("first_name")}
              error={userRegister("first_name").errors?.first_name?.message}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              radius={10}
              size="lg"
              placeholder={t("surname")}
              label={t("surname")}
              withAsterisk
              {...userRegister("last_name")}
              error={userRegister("last_name").errors?.last_name?.message}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col sm={6}>
            <TextInput
              radius={10}
              size="lg"
              placeholder={t("e-mail")}
              label={t("e-mail")}
              withAsterisk
              defaultValue={user?.email}
              disabled
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              radius={10}
              size="lg"
              placeholder={t("mobile")}
              label={t("mobile")}
              withAsterisk
              {...userRegister("number")}
              error={userRegister("number").errors?.number?.message}
            />
          </Grid.Col>
        </Grid>
        <TextInput
          radius={10}
          size="lg"
          placeholder={t("address")}
          label={t("location")}
          withAsterisk
          {...userRegister("address")}
          error={userRegister("address").errors?.address?.message}
        />
        <Grid justify="space-between">
          <Grid.Col sm={3}>
            <Select
              radius={10}
              size="lg"
              placeholder={t("select")}
              label={t("language-certification")}
              withAsterisk
              name="skill"
              value={selectedSkill}
              data={
                certificationTypes?.map((type) => ({
                  value: type.id,
                  label: type.skill,
                })) || []
              }
              onChange={(value) => {
                setSelectedSkill(value);
                setCertValue("skill", value);
              }}
              error={certRegister("skill").errors?.skill?.message}
            />
          </Grid.Col>
          <Grid.Col sm={2}>
            <TextInput
              radius={10}
              size="lg"
              placeholder={t("grade")}
              label={t("grade")}
              withAsterisk
              name="grade"
              value={pointValue}
              onChange={(e) => {
                setPointValue(e.target.value);
                setCertValue("point", e.target.value);
              }}
            />
          </Grid.Col>
          <Grid.Col sm={7}>
            <Grid align="flex-end">
              <Grid.Col sm={showAddButton ? 9 : 12}>
                <CustomFileInput
                  t={t}
                  label={t("certificate")}
                  register={certRegister}
                  setValue={setCertValue}
                  name="certificate"
                  type="upload"
                  setShowAddButton={setShowAddButton}
                  watch={selectedFile}
                  setWatch={setSelectedFile}
                />
              </Grid.Col>
              <Grid.Col sm={3}>
                {showAddButton && (
                  <Button
                    styles={() => ({
                      root: {
                        width:"100%",
                        background: "#FE6027",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: 500,
                        "&:hover": {
                          background: "#FE6027",
                        },
                      },
                    })}
                    leftIcon={!isCreateCertificateLoading && <Plus />}
                    size="lg"
                    radius={10}
                    onClick={handleCertSubmit(handleCertFormSubmit)}
                    disabled={isCreateCertificateLoading}
                  >
                    {isCreateCertificateLoading ? (
                      <Loader style={{ color: "#fff" }} variant="dots" />
                    ) : (
                      t("add")
                    )}
                  </Button>
                )}
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        {userCertifications?.map((certification) => {
          return (
            <UploadedFiles key={certification.id}>
              <Group spacing={xs ? 4 :16}>
                <Text size={xs ? 13 :16}>{certification.skill.skill}</Text>
                <ChevronRight
                  size={xs? 14 : 18}
                  strokeWidth={1.75}
                  style={{ marginTop: "2px" }}
                />
                {editingId === certification.id ? (
                  <TextInput
                    size="sm"
                    defaultValue={certification.point}
                    onChange={(e) => setCertValue("point", e.target.value)}
                  />
                ) : (
                  <Text size={xs ? 13 : 16} style={xs ? {marginLeft  : "5px"} : { marginLeft: "10px" }}>{certification.point}</Text>
                )}
              </Group>

              <Group>
                {editingId === certification.id ? (
                  <>
                    <Circle
                      backgroundColor="#D6F4F6"
                      onClick={handleCertSubmit(handleCertFormSubmit)}
                    >
                      <Check size={xs ? 18 : 20} color="#029199" strokeWidth={1.5} />
                    </Circle>
                    <Circle
                      backgroundColor="#D6F4F6"
                      onClick={() => setEditingId(null)}
                    >
                      <X size={xs ? 18 : 20} color="#029199" strokeWidth={1.5} />
                    </Circle>
                  </>
                ) : (
                  <Circle
                    backgroundColor="#D6F4F6"
                    onClick={() => handleButtonClick(certification.id)}
                  >
                    <Edit size={xs ? 18 : 20} color="#029199" strokeWidth={1.5} />
                  </Circle>
                )}

                <Circle
                  onClick={() => deleteCertificate(certification.id)}
                  backgroundColor="#FFE0E0"
                >
                  <Trash size={xs ? 18 : 20} color="#E41D1D" strokeWidth={1.5} />
                </Circle>


                <Button
                  rightIcon={<Download strokeWidth={1.5} color="#029199" />}
                  style={{
                    border: "1px solid #029199",
                    background: "#EFF8FF",
                    color: "#029199",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                  className="download"
                  radius={10}
                >
                  <a
                    style={{ textDecoration: "none", color: "#029199" }}
                    href={certification.certificate}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("download")}
                  </a>
                </Button>
              </Group>
            </UploadedFiles>
          );
        })}
        <input
          type="file"
          id="hiddenFileInput"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleHiddenFileChange}
        />

        <Button
          type="submit"
          style={{
            backgroundColor: "#FE6027",
            borderRadius: "10px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: 400,
            marginTop: "10px",
          }}
          size="lg"
          disabled={isEditUserLoading}
        >
          {isEditUserLoading ? (
            <Loader style={{ color: "#fff" }} variant="dots" />
          ) : (
            t("next-step")
          )}
        </Button>
      </Stack>
    </form>
  );
};

export default UserInfoStep;

const UploadedFiles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;
  border: 1px solid #11af22;
  padding: 6px 12px;
  border-radius: 16px;
`;

const Circle = styled.div`
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
   width: 26px;
   height: 26px;
  }
`;
