import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../../Helpers/Axios";
import {
  Button,
  Grid,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import {
  Check,
  Confetti,
  Edit,
  Paperclip,
  Plus,
  Trash,
  X,
} from "tabler-icons-react";
import CustomFileInput from "./CustomFileInput";
import CustomCheckbox from "../Universities/Filter/CustomCheckbox";
import styled from "styled-components";
import admission from "../../../Assets/SVGs/university/admission.svg";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaQuery } from "@mantine/hooks";
import recommendationPDF from "../../../Assets/PDF/recommendation.pdf";

const fetchProfession = async (id) => {
  const { data } = await axiosInstance.get(`/university/professionbyid/${id}/`);
  return data;
};

// Function for file validation

const AdmissionInfoStep = ({ props }) => {
  const {
    t,
    user,
    pId,
    aId,
    setStep,
    professionIds,
    setProfessionIds,
    applyTo,
    handleAdmissionInfoSubmit,
    handleAdditionalInfoSubmit,
    handleProfessionSubmit,
  } = props;

  const fileValidation = yup
    .mixed()
    .required(t("required"))
    .test("fileFormat", t("fileFormat"), (value) => {
      return value && value.type === "application/pdf";
    })
    .test("fileSize", t("fileSize"), (value) => {
      return value && value.size <= 5 * 1024 * 1024; // 5MB
    });

  const requiredValidation = yup
    .mixed()
    .required(t("requiredMotivationLetter"));

  const fileFormatValidation = yup
    .mixed()
    .test("fileFormat", t("fileFormat"), function (value) {
      // Check if the field is empty
      if (!value) {
        return true; // Return true if the field is empty
      }
      // Check if the file type is PDF
      if (value && value.type !== "application/pdf") {
        return this.createError({
          message: "Only PDF files are allowed",
          path: this.path,
        });
      }
      // Check if the file size is within limits (5MB)
      if (value && value.size > 5 * 1024 * 1024) {
        return this.createError({
          message: "File size is too large. Maximum 5MB allowed",
          path: this.path,
        });
      }
      return true; // Return true if all conditions are satisfied
    });

  const [activeTemplateId, setActiveTemplateId] = useState(null);

  const formSchema = yup.object().shape({
    passport: !activeTemplateId && fileValidation,
    cv: !activeTemplateId && fileValidation,
    attestat: !activeTemplateId && fileValidation,
    reference_file: !activeTemplateId && fileValidation,
    certificate_graduation: !activeTemplateId && fileFormatValidation,
    motivation_letter: !activeTemplateId && requiredValidation,
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors: admissionErrors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const [exampleCheckValue, setExampleCheckValue] = useState(false);
  const [additionalFile, setAdditionalFile] = useState(null);
  const queryClient = useQueryClient();
  const [isTemplate, setIsTemplate] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [editActiveId, setEditActiveId] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [titleError, setTitleError] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [clickAgainApply, setClickAgainApply] = useState(false);

  const [applyedProfessions, setApplyedProfessions] = useState([]);
  const navigate = useNavigate();

  const link =
    applyTo === "university"
      ? "/university/apply/create/"
      : "/university/agent/apply/create/";

  const { data: profession } = useQuery({
    queryKey: ["professions", pId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`/university/professionbyid/${pId}/`);
      return data;
    },
    enabled: !!pId,
  });

  const { data: additionFilesList } = useQuery({
    queryKey: ["additional-files-list"],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`/university/additionalfiles/list/`);
      return data;
    },
  });

  const { mutate: createAdditionalFile, isLoading: isCreateFileLoading } =
    useMutation({
      mutationFn: async (data) => {
        const formData = new FormData();
        formData.append("user", user?.id);
        formData.append("file", data.file);
        formData.append("file_name", data.file_name);
        await axiosInstance.post(`/university/additionalfiles/create/`, formData);
        return data;
      },
      onSuccess: (data) => {
        showNotification({
          icon: <Confetti />,
          title: "Success",
          message: "File added successfully.",
          color: "teal",
        });
        queryClient.invalidateQueries([`additional-files-list`]);
        setAdditionalFile(null);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const handleCreateAdditionalFile = () => {
    if (additionalFile && !additionalFile?.file_name) {
      setTitleError("Title is required");
    } else if (
      additionalFile &&
      additionalFile?.file?.type !== "application/pdf"
    ) {
      setFileError("Only PDF files are allowed");
    } else {
      createAdditionalFile(additionalFile);
      setTitleError(null);
      setFileError(null);
    }
  };

  const { mutate: deleteAdditionalFiles } = useMutation({
    mutationFn: async (id) =>
      await axiosInstance
        .delete(`/university/additionalfiles/edit/${id}/`)
        .then((res) => res?.data),
    onSuccess: (data) => {
      showNotification({
        icon: <Confetti />,
        title: "Success",
        message: "File deleted successfully.",
        color: "teal",
      });
      queryClient.invalidateQueries([`additional-files-list`]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { data: admissionInfoList } = useQuery({
    queryKey: ["admission-info-list"],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `/university/student_admission_info/list/`
      );
      return data;
    },
  });

  const { mutate: createAdmission, isLoading: isCreateAdmissionLoading } =
    useMutation({
      mutationFn: async (data) =>
        await axiosInstance
          .post(`/university/student_admission_info/create/`, data)
          .then((res) => res?.data),
      onSuccess: (data) => {
        if (!isPreview) {
          const formData = new FormData();
          formData.append("user", user?.id);
          applyTo === "agents" && formData.append("agent", aId);
          applyTo === "university"
            ? formData.append("programs", pId)
            : professionIds &&
              professionIds.map((professionId) =>
                formData.append("programs", professionId)
              );

          formData.append("info", data.id);
          additionFilesList &&
            additionFilesList.map((additionFile) =>
              formData.append("additional_files", additionFile.id)
            );
          apply(formData);
        } else {
          setActiveTemplate(data);
          handleAdmissionInfoSubmit(data);
          setStep("preview");
        }
        queryClient.invalidateQueries([`admission-info-list`]);
      },
    });

  const { mutate: deleteAdmission } = useMutation({
    mutationFn: async (id) =>
      await axiosInstance
        .delete(`/university/student_admission_info/edit/${id}/`)
        .then((res) => res?.data),
    onSuccess: (data) => {
      setActiveTemplateId(null);
      setActiveTemplate(null);
      window.scrollTo({ top: 0, behavior: "smooth" });
      queryClient.invalidateQueries([`admission-info-list`]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: updateAdmission } = useMutation({
    mutationFn: async ({ id, data }) => {
      await axiosInstance.patch(`/university/student_admission_info/edit/${id}/`, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`admission-info-list`]);
      setEditActiveId(null);
      setIsTemplate(false);
      setIsChanged((prev) => !prev);
      showNotification({
        icon: <Confetti />,
        title: "Success",
        message: "Template updated successfully.",
        color: "teal",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: apply, isLoading: isApplyLoading } = useMutation({
    mutationFn: async (data) =>
      await axiosInstance.post(`${link}`, data).then((res) => res?.data),
    onSuccess: () => {
      showNotification({
        icon: <Confetti />,
        title: "Success",
        message: "Application submitted successfully.",
        color: "teal",
      });
      setProfessionIds([]);
      navigate(
        applyTo === "university"
          ? "/universities"
          : `/agents/${aId}/universities`
      );
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("user", user.id);
    data.motivation_letter &&
      formData.append("motivation_letter", data.motivation_letter);
    data.reference_file &&
      formData.append("reference_file", data.reference_file);
    data.passport && formData.append("passport", data.passport);
    data.certificate_graduation &&
      formData.append("certificate_graduation", data.certificate_graduation);
    data.attestat && formData.append("attestat", data.attestat);
    data.cv && formData.append("cv", data.cv);
    data.title
      ? formData.append("title", data.title)
      : formData.append("title", "Template");

    if (editActiveId) {
      updateAdmission({ id: editActiveId, data: formData });
    } else if (admissionInfoList?.length === 0) {
      createAdmission(formData);
    } else {
      if (isApply) {
        const formData = new FormData();
        formData.append("user", user?.id);
        applyTo === "university"
          ? formData.append("programs", pId)
          : professionIds &&
            professionIds.map((professionId) =>
              formData.append("programs", professionId)
            );
        applyTo === "agents" && formData.append("agent  ", aId);
        formData.append("info", activeTemplateId);
        additionFilesList &&
          additionFilesList.map((additionFile) =>
            formData.append("additional_files", additionFile.id)
          );

        apply(formData);
      } else {
        setStep("preview");
      }
    }
  };

  const addFileToState = (file) => {
    setAdditionalFile({ user: user?.id, file, file_name: "" });
  };

  const handleTitleChange = (title) => {
    setAdditionalFile((prev) => ({ ...prev, file_name: title }));
  };

  const handleHiddenFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAdditionalFile({ user: user?.id, file, file_name: "" });
    }
  };

  const filterActiveTemplateById = (id) => {
    const template = admissionInfoList.find((template) => template.id === id);
    setActiveTemplate(template);
    handleAdmissionInfoSubmit(template);
  };

  useEffect(() => {
    queryClient.invalidateQueries([`admission-info-list`]);
  }, []);

  useEffect(() => {
    if (activeTemplateId) {
      filterActiveTemplateById(activeTemplateId);
    }
  }, [activeTemplateId, isChanged, admissionInfoList]);

  useEffect(() => {
    profession && handleProfessionSubmit(profession.profession);
  }, [profession]);

  useEffect(() => {
    additionFilesList && handleAdditionalInfoSubmit(additionFilesList);
  }, [additionFilesList]);

  useEffect(() => {
    if (
      admissionInfoList &&
      admissionInfoList?.length > 0 &&
      !activeTemplateId
    ) {
      setActiveTemplateId(admissionInfoList[0].id);
    }
  }, [admissionInfoList]);

  useEffect(() => {
    if (isApply || isPreview) {
      handleSubmit(onSubmit)();
    }
  }, [isApply, isPreview, clickAgainApply]);

  useEffect(() => {
    if (professionIds?.length > 0) {
      const fetchDataForIds = async () => {
        try {
          const results = await Promise.all(
            professionIds.map((id) => fetchProfession(id))
          );
          setApplyedProfessions(results);
          handleProfessionSubmit(results);
        } catch (err) {
        } finally {
        }
      };
      fetchDataForIds();
    }
  }, [professionIds]);

  useEffect(() => {
    if (Object.keys(admissionErrors).length !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [admissionErrors]);

  const combinedString = applyedProfessions
    .map((obj) => obj.profession)
    .join("/");

  const xs = useMediaQuery("(max-width: 900px)");

  const handleDownload = () => {
    if (exampleCheckValue) {
      try {
        const link = document.createElement('a');
        link.href = recommendationPDF;
        link.download = 'recommendation.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('PDF download failed:', error);
        // İstifadəçiyə xəta bildirişi göstərmək olar
        showNotification({
          title: "Error",
          message: "Failed to download PDF file",
          color: "red"
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={20}>
        <Grid gutter={xs ? 10 : 30}>
          <Grid.Col sm={6}>
            <TextInput
              radius={10}
              size="lg"
              label={t("program")}
              style={{ overflow: "visible" }}
              withAsterisk
              defaultValue={
                applyTo === "university"
                  ? profession?.profession
                  : combinedString
              }
              disabled
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <CustomFileInput
              t={t}
              disabled={editActiveId === null && activeTemplateId !== null}
              name="cv"
              label={t("cv")}
              register={register}
              setValue={setValue}
              watch={watch("cv")}
              type={activeTemplate && "download"}
              to={activeTemplate && activeTemplate?.cv}
              error={admissionErrors?.cv?.message}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <CustomFileInput
              t={t}
              disabled={editActiveId === null && activeTemplateId !== null}
              label={t("attestat")}
              register={register}
              setValue={setValue}
              name="attestat"
              watch={watch("attestat")}
              type={activeTemplate && "download"}
              to={activeTemplate && activeTemplate?.attestat}
              error={admissionErrors?.attestat?.message}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <CustomFileInput
              t={t}
              disabled={editActiveId === null && activeTemplateId !== null}
              label={t("passport")}
              register={register}
              setValue={setValue}
              name="passport"
              watch={watch("passport")}
              type={activeTemplate && "download"}
              to={activeTemplate && activeTemplate?.passport}
              error={admissionErrors?.passport?.message}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <CustomFileInput
              t={t}
              disabled={editActiveId === null && activeTemplateId !== null}
              label={t("certificate-of-graduation")}
              register={register}
              setValue={setValue}
              name="certificate_graduation"
              watch={watch("certificate_graduation")}
              type={activeTemplate && "download"}
              to={activeTemplate && activeTemplate?.certificate_graduation}
              error={admissionErrors?.certificate_graduation?.message}
            />
          </Grid.Col>
        </Grid>

        <Stack spacing={10}>
          <CustomFileInput
            t={t}
            disabled={editActiveId === null && activeTemplateId !== null}
            label={t("letter-of-recommendation")}
            register={register}
            setValue={setValue}
            name="reference_file"
            watch={watch("reference_file")}
            type={activeTemplate && "download"}
            to={activeTemplate && activeTemplate?.reference_file}
            error={admissionErrors?.reference_file?.message}
          />
          <Stack spacing={12}>
            <Group noWrap >
              <CustomCheckbox
                checked={exampleCheckValue}
                onChange={() => setExampleCheckValue(!exampleCheckValue)}
              />
              <Text
                sx={xs ? {width : "90%"} : { width: "40%" }}
                color="#00BD90"
                size={14}
                weight={500}
              >
                {t("sample-file-below")}
              </Text>
            </Group>
            <Button
              variant="outline"
              size="sm"
              radius={10}
              className="download-btn"
              sx={{ 
                width: "210px", 
                height: "40px",
                opacity: exampleCheckValue ? 1 : 0.5 
              }}
              rightIcon={
                <Paperclip
                  style={{ rotate: "-45deg" }}
                  size={16}
                  strokeWidth={2}
                  color="#fff"
                />
              }
              disabled={!exampleCheckValue}
              onClick={handleDownload}
            >
              {t("download-example")}
            </Button>
          </Stack>
        </Stack>

        <div
          style={{ width: "100%", height: "1px", background: "#E1E1E1" }}
        ></div>

        <Stack spacing={10}>
          <CustomFileInput
            t={t}
            label={t("additional-document")}
            setValue={setValue}
            addFileToState={addFileToState}
            hiddenInputId="hiddenFileInput"
            name="additional-document"
          />
          <input
            type="file"
            id="hiddenFileInput"
            style={{ display: "none" }}
            onChange={handleHiddenFileChange}
          />{" "}
          <Text size={14} weight={400} color="#029199" span>
            {t("uploaded")} - {additionFilesList?.length}/
            {additionFilesList?.length} {t("files")}
          </Text>
          {additionFilesList &&
            additionFilesList.map((additionFile) => (
              <FileInfoContainer key={additionFile.id}>
                <Grid align="center">
                  <Grid.Col sm={9}>
                    <TextInput
                      radius={10}
                      disabled
                      size="md"
                      placeholder={t("add-title")}
                      value={additionFile.file_name}
                    />
                  </Grid.Col>
                  <Grid.Col sm={3}>
                    <Group position="right" grow noWrap spacing={2}>
                      <Button
                        style={{
                          background: "#FE6027",
                          color: "fff",
                          fontSize: "16px",
                        }}
                        leftIcon={<Trash color="#fff" />}
                        size="md"
                        radius={10}
                        onClick={() => deleteAdditionalFiles(additionFile?.id)}
                      >
                        {t("delete-button-experience")}
                      </Button>
                      <Button
                        style={{
                          background: "#00BD90",
                          color: "fff",
                          fontSize: "16px",
                        }}
                        leftIcon={<Plus color="#fff" />}
                        size="md"
                        radius={10}
                        onClick={() =>
                          document.querySelector(`#hiddenFileInput`).click()
                        }
                      >
                        {t("add-file")}
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </FileInfoContainer>
            ))}
        </Stack>

        {additionalFile && (
          <FileInfoContainer>
            <Grid>
              <Grid.Col sm={6}>
                <div className="file-name">
                  <Text>{additionalFile.file.name}</Text>
                </div>
                <Text size={16} fontWeight={400} color="red">
                  {fileError}
                </Text>
              </Grid.Col>
              <Grid.Col sm={3}>
                <TextInput
                  radius={10}
                  size="lg"
                  placeholder={t("add-title")}
                  error={titleError}
                  withAsterisk
                  value={additionalFile.title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col sm={3}>
                <Group grow>
                  <Button
                    style={{
                      background: "#00BD90",
                      color: "fff",
                      fontSize: "16px",
                    }}
                    leftIcon={!isCreateFileLoading && <Check color="#fff" />}
                    size="lg"
                    radius={10}
                    onClick={handleCreateAdditionalFile}
                    disabled={isCreateFileLoading}
                  >
                    {isCreateFileLoading ? (
                      <Loader color="#fff" variant="dots" />
                    ) : (
                      t("save")
                    )}
                  </Button>
                  <Button
                    styles={(theme) => ({
                      root: {
                        background: "#F9FAFC",
                        borderRadius: "10px",
                        border: "1px solid #E7E9ED",
                        color: "#364152",
                        fontSize: "16px",
                        fontWeight: "500",
                        "&:hover": {
                          background: "#F9FAFC",
                        },
                      },
                    })}
                    size="lg"
                    radius={10}
                    onClick={() => setAdditionalFile(null)}
                    disabled={isCreateFileLoading}
                  >
                    {t("back")}
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </FileInfoContainer>
        )}

        <Textarea
          disabled={editActiveId === null && activeTemplateId !== null}
          defaultValue={
            activeTemplateId ? activeTemplate?.motivation_letter : ""
          }
          placeholder={t("state-the-reason")}
          label={t("motivation-letter")}
          watch={watch("motivation_letter")}
          radius={10}
          size="lg"
          autosize
          minRows={6}
          error={admissionErrors?.motivation_letter?.message}
          name="motivation_letter"
          onChange={(e) => setValue("motivation_letter", e.target.value)}
        />
        <Group>
          <CustomCheckbox
            checked={isTemplate}
            onChange={() => setIsTemplate(!isTemplate)}
          />
          <Text sx={{ width: "40%" }} color="#00BD90" size={14} weight={500}>
            {t("save-the-template")}
          </Text>
        </Group>
        {isTemplate && (
          <TextInput
            radius={10}
            size="sm"
            label={t("template-name")}
            withAsterisk
            defaultValue={activeTemplate && activeTemplate.title}
            onChange={(e) => setValue("title", e.target.value)}
          />
        )}

        <Stack>
          {admissionInfoList &&
            admissionInfoList.map((admissionInfo, index) => (
              <Stack key={admissionInfo.id}>
                <Group position="apart">
                  <Group>
                    <img src={admission} alt="icon" />
                    <Text color="#364152" size={16} weight={500}>
                      {admissionInfo.title}
                    </Text>
                    <CustomCheckbox
                      checked={activeTemplateId === admissionInfo.id}
                      onChange={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setActiveTemplateId(admissionInfo.id);
                      }}
                    />
                  </Group>
                  <Group>
                    {editActiveId === admissionInfo.id ? (
                      <>
                        <Circle
                          backgroundColor="#D6F4F6"
                          onClick={() => handleSubmit(onSubmit)()}
                        >
                          <Check color="#029199" strokeWidth={1.5} />
                        </Circle>
                        <Circle
                          backgroundColor="#D6F4F6"
                          onClick={() => {
                            setEditActiveId(null);
                            setIsTemplate(false);
                          }}
                        >
                          <X color="#029199" strokeWidth={1.5} />
                        </Circle>
                      </>
                    ) : (
                      <Circle backgroundColor="#D6F4F6">
                        <Edit
                          onClick={() => {
                            setActiveTemplateId(admissionInfo.id);
                            setEditActiveId(admissionInfo.id);
                            setIsTemplate(true);
                          }}
                          color="#029199"
                          strokeWidth={1.5}
                        />
                      </Circle>
                    )}

                    <Circle backgroundColor="#FFE0E0">
                      <Trash
                        onClick={() => deleteAdmission(admissionInfo?.id)}
                        color="#E41D1D"
                        strokeWidth={1.5}
                      />
                    </Circle>
                  </Group>
                </Group>
                {admissionInfoList.length - 1 !== index && (
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      background: "#DEDEDE",
                    }}
                  ></div>
                )}
              </Stack>
            ))}
        </Stack>

        <Button
          onClick={() => {
            setIsApply(true);
            setIsPreview(false);
            setClickAgainApply((prev) => !prev);
          }}
          style={{
            backgroundColor: "#FE6027",
            borderRadius: "10px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: 400,
            marginTop: "10px",
          }}
          size="lg"
          disabled={isApplyLoading || isCreateAdmissionLoading}
        >
          {isApplyLoading || isCreateAdmissionLoading ? (
            <Loader color="#fff" variant="dots" />
          ) : (
            t("apply")
          )}
        </Button>
        <Group align="center" position="center" spacing={10}>
          <Line />
          <Text size={16} weight={400} color="#707070">
            {t("or")}
          </Text>
          <Line />
        </Group>
        <Button
          onClick={() => {
            setIsPreview(true);
            setIsApply(false);
            setClickAgainApply((prev) => !prev);
          }}
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "1px solid #D7D7D7",
            color: "#858585",
            fontSize: "16px",
            fontWeight: 400,
            marginTop: "10px",
          }}
          disabled={isApplyLoading || isCreateAdmissionLoading}
          size="lg"
        >
          {isApplyLoading || isCreateAdmissionLoading ? (
            <Loader color="#000" variant="dots" />
          ) : (
            t("preview")
          )}
        </Button>
      </Stack>
    </form>
  );
};

export default AdmissionInfoStep;

const FileInfoContainer = styled.div`
  .file-name {
    border: 1px solid #11af22;
    border-radius: 10px;
    background: #fff;
    padding: 11px 10px 11px 18px;
  }
`;
const Circle = styled.div`
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Line = styled.div`
  width: 20%;
  height: 1px;
  background: #ececec;
`;
