import React, { useState } from "react";
import { Button, Stack, ScrollArea } from "@mantine/core";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import welcome from "../../../Assets/SVGs/steams/sidebar/welcome.svg";
import main from "../../../Assets/SVGs/steams/sidebar/main.svg";
import comment from "../../../Assets/SVGs/steams/sidebar/comment.svg";
import {useMediaQuery} from '@mantine/hooks'
import { useTranslation } from "react-i18next";
const SteamSidebarContent = ({ type, id }) => {
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const { pathname } = useLocation();
  const xs = useMediaQuery("(max-width: 576px)");
  const { t } = useTranslation();
  
  const steamNavs = [
    {
      title: t("glad_welcome"),
      to: `/${type}/${id}/overview`,
      icon: welcome,
      isDropdown: false,
    },
    {
      title: t("main"),
      to: `/${type}/${id}/${type === "steam" ? "week-pathway" : "section-pathway"}`,
      icon: main,
      isDropdown: false,
    },

    {
      title: t("task-scores-comments"),
      to: `/${type}/${id}/task-score`,
      icon: comment,
      isDropdown: false,
    },
  ];
  return (
    <ScrollArea scrollbarSize={2} type={xs ? "scroll" : "never"} style={{ width: '100%' }}>
      <Stack 
        spacing={xs ? 10 : 2} 
        mt={10} 
        style={{
          padding: xs ? "5px 0" :  "0 20px",
          background : xs ? "#fff" : "",
          flexDirection: xs ? "row" : "column",
          minWidth: xs ? steamNavs.length * 150 : 'auto' // Adjust 150 based on your button width
        }}
      >
        {steamNavs.map((navLink, index) => (
          <NavLink
            key={index}
            props={{
              type,
              title: navLink.title,
              to: navLink.to,
              icon: navLink.icon,
              currentDropdown,
              pathname,
              isDropdown: navLink.isDropdown,
              setCurrentDropdown,
            }}
          />
        ))}
      </Stack>
    </ScrollArea>
  );
};

export default SteamSidebarContent;

export const NavLink = ({ props }) => {
  const { title, to, icon, setCurrentDropdown, pathname,type } = props;
  return (
    <>
      <Button
        size="lg"
        radius={10}
        component={Link}
        to={to}
        leftIcon={<img src={icon} alt="icon" />}
        onClick={() => setCurrentDropdown(null)}
        styles={(theme) => ({
          root: {
            backgroundColor : (pathname === to && type === "steam") ? "#ccf075" : (pathname === to && type === "bootcamp") ? "#ffcc42" : "transparent",
            color: "#364152",
            display: "flex",
            justifyContent: "flex-start",
            fontSize: "16px",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: (type === "steam" ? "#CCF075" : "#FFCC42"),
            },
          },
        })}
      >
        {title}
      </Button>
    </>
  );
};
