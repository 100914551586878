import { Button } from "@mantine/core";
import axiosInstance from "../../Helpers/Axios";
import { Link, useNavigate } from "react-router-dom";
import { FreeCourseStyle } from "./PaymentStyle";

export function FreeCourse({ props }) {
  const {t, type, texts, links, userLoggedIn, user, entryData ,promoEntered} = props;

  const navigate = useNavigate();

  async function buyFreeCourse() {
    const formX = new FormData();
    if (type === "internship") {
      formX.append("intershipinfo", entryData?.id);
    }
    if (type === "course") {
      formX.append("course", entryData?.id);
    }

    if (type === "steam" ) {
      formX.append("steam", entryData?.id);
    }
    if (type === "bootcamp") {
      formX.append("bootcamp", entryData?.id);
    }
    formX.append("promocode", promoEntered);
    formX.append("bank_installment_paid",false)

    const response = await axiosInstance.post(links.create_order, formX);
    if (response.status === 201) {
      if (type === "internship") {
        navigate(`/internship-content/${entryData?.id}`);
      }
      if (type === "course") {
        navigate(`/courses/${entryData?.id}`);
      }

      if (type === "steam") {
        navigate(`/steam/${entryData?.id}`);
      }
      if (type === "bootcamp") {
        navigate(`/bootcamps/${entryData?.id}`);
      }
    }
  }

  return (
    <FreeCourseStyle>
      {/* <div className="price">
        <ins>Price:</ins>
        <h2 style={{marginTop:"0rem"}}>FREE</h2>
      </div> */}

      {userLoggedIn ? (
        <Button
          fullWidth
          className="payment-btn"
          onClick={() => buyFreeCourse()}
        >
          {texts.apply}: {t('FREE')}
        </Button>
      ) : (
        <Button
          fullWidth
          size="lg"
          className="loggedOutPayButton"
          component={Link}
          to="/login"
        >
          {texts.log_in}
        </Button>
      )}
    </FreeCourseStyle>
  );
}
