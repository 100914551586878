import React from "react";
import styled from "styled-components";
import { Group, Stack, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";

const JoinedPrograms = ({t,joinedProgram,type}) => {
 

  return (
    <ActivitiesContainer>
      <div className="activities">
        <Title
          sx={{ marginBottom: "15px" }}
          weight={500}
          color="#364152"
          size={16}
        >
          { type === "course" ? t('joined-course') : t('joined-steam')}
        </Title>
        <ScrollableStack>
          {joinedProgram?.map((program, index) => (
            <React.Fragment key={index}>
              <Group  noWrap align="center">
                <div
                  style={{
                    width: "70px",
                    height: "45px",
                    borderRadius: "11px",
                  }}
                >
                  <img
                    style={{
                      width: "70px",
                      height: "45px",
                      borderRadius: "11px",
                      objectFit: "cover"
                    }}
                    src={ type === "course" ? program?.bootcamp_image : program?.steam_image}
                    alt="program_image"
                  />
                </div>
                <Text component={Link} to={`/${type === "course" ? "bootcamp" : "steam"}/${program?.id}`} size={14} weight={400} color="#364152">
                  {type === "course" ? program?.bootcamp_name : program?.steam_name}
                </Text>
              </Group>
              {index !== joinedProgram.length - 1 && (
                <div
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: "#DCDCDC",
                  }}
                ></div>
              )}
            </React.Fragment>
          ))}
        </ScrollableStack>
      </div>
    </ActivitiesContainer>
  );
};

export default JoinedPrograms;

const ScrollableStack = styled(Stack)`
  max-height: 200px;
  overflow-y: hidden;
  
  &:hover {
    overflow-y: auto;
  }
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ActivitiesContainer = styled.div`
  width: 100%;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  .activities {
    padding: 16px 18px;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
