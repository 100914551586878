import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Group, Text, Title } from "@mantine/core";
import { HourglassHigh, Language, Tag } from "tabler-icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import parse from "html-react-parser";
import ProgramButtons from "./ProgramButtons";

const PlayIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="rgba(0, 0, 0, 0.5)"/>
    <path d="M42 32L26 41.5885L26 22.4115L42 32Z" fill="white"/>
  </svg>
);

const ProgramInfo = ({
  data,
  xs,
  isAppliedProgram,
  programStatus,
  type,
  sectionList,
  user,
  t,
  link
}) => {
  const queryClient = useQueryClient();
  const isLoggedin = user?.id ? true : false;
  const allActive = sectionList?.every((section) => section.section_active);
  const allInactive = sectionList?.every((section) => !section.section_active);
  const someActive = sectionList?.some((section) => section.section_active);
  
  const getKey = (steamKey, courseKey) => {
    if (data) {
      if (type === "steam" || type === "bootcamp") {
        return data?.[steamKey];
      } else {
        if (courseKey === "instructor_name") {
          const teacher = data?.teacher || {};
          return `${teacher.first_name || ""} ${
            teacher.last_name || ""
          }`.trim();
        } else if (courseKey === "language") {
          const language = data?.language.language || {};
          return language;
        }
        return data?.[courseKey];
      }
    }
  };

  const { mutate: applyProgram, isLoading } = useMutation({
    mutationKey: ["applyProgram", data?.id],
    mutationFn: async () => {
      const response = await axios.post(`${link.program}${data?.id}/apply`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["isApplied", data?.id]);
    },
    onError: () => {
      alert("An error occurred while applying the program");
    },
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const error = null;

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const getYouTubeEmbedUrl = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };

  const videoUrl = getKey(type === "bootcamp" ? "bootcamp_video_file" : "steam_video_file", "video");
  const embedUrl = videoUrl ? getYouTubeEmbedUrl(videoUrl) : null;

  return (
    <section
      style={{
        paddingTop: '20px',
        borderBottom: "1px solid #c2c2c2",
        paddingBottom: "40px",
        maxWidth: "92%",
        margin: "0 auto",
      }}
    >
      <Grid>
        <Grid.Col lg={7}>
          <ProgramInfoStyle>
            <Info>
              <Title mb={15} size={xs ? 18 : 24} weight={500} color="#364152">
                {getKey(type === "bootcamp" ? "bootcamp_name" : "steam_name", "title")}
              </Title>
              <Text size={xs ? 16 : 18} weight={400} color="#888888">
                {t("author")}: {getKey("author_name", "instructor_name")}
              </Text>
              <Line />
              <Text size={xs ? 14 : 16} weight={400} color="#364152">
                {parse(`${getKey(type === "bootcamp" ? "bootcamp_about" : "steam_about", "description")}`)}
              </Text>
            </Info>
            <ProgramButtons
              user={user}
              isAppliedProgram={isAppliedProgram}
              programStatus={programStatus}
              onApply={applyProgram}
              isLoading={isLoading}
              programId={data?.id}
              syllabusFile={(type === "steam" || type === "bootcamp") ? data?.syllabus_file : data?.file}
              xs={xs}
              buttonWidth={xs ? "100%" : "48%"} // or any other value you prefer
              buttonHeight="45px" // or any other value you prefer
              type={type}
              isLoggedin={isLoggedin}
              allActive={allActive}
              allInactive={allInactive}
              someActive={someActive}
              entryData={data}
              t={t} // Replace this with your actual translation function
            />
          </ProgramInfoStyle>
        </Grid.Col>
        <Grid.Col lg={5}>
          <ProgramBanner>
            {embedUrl ? (
              <div style={{ position: "relative", width: "100%", height: "375px" }}>
                {isPlaying ? (
                  <iframe
                    style={{
                      borderRadius: "11px",
                      width: "100%",
                      height: "375px",
                      border: "none",
                    }}
                    src={`${embedUrl}?autoplay=1`}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="Program Video" // Add this line
                  />
                ) : (
                  <>
                    <img
                      style={{
                        borderRadius: "11px",
                        width: "100%",
                        height: "375px",
                        objectFit: "fill",
                      }}
                      src={getKey(type === "bootcamp" ? "bootcamp_image_file" : "steam_image_file", "image")}
                      alt="program_preview"
                    />
                    <div
                      onClick={handlePlayClick}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                        transition: "transform 0.2s ease-in-out",
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.transform = "translate(-50%, -50%) scale(1.1)"}
                      onMouseLeave={(e) => e.currentTarget.style.transform = "translate(-50%, -50%) scale(1)"}
                    >
                      <PlayIcon />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <img
                style={{
                  borderRadius: "11px",
                  width: "100%",
                  height: "375px",
                  objectFit: "fill",
                }}
                src={getKey(type === "bootcamp" ? "bootcamp_image_file" : "steam_image_file", "image")}
                alt="program_image"
              />
            )}
            {error && <div style={{ color: "red" }}>{error}</div>}
            <Credentials>
              <Group spacing={xs ? 10 : 16} style={{ padding: "20px 30px" }}>
                <Group spacing={xs ? 4 : 7}>
                  <Language
                    strokeWidth={1.75}
                    color="#62657D"
                    size={xs ? 18 : 20}
                  />
                  <Text size={xs ? 14 : 16} weight={400} color="#3B3F5C" span>
                    {getKey("language", "language")}
                  </Text>
                </Group>
                <div
                  style={{
                    width: "1px",
                    height: "11px",
                    background: "#62657D",
                  }}
                ></div>
                <Group spacing={xs ? 4 : 7}>
                  <HourglassHigh
                    strokeWidth={1.75}
                    color="#62657D"
                    size={xs ? 18 : 20}
                  />
                  <Text size={xs ? 14 : 16} weight={400} color="#3B3F5C" span>
                    {getKey("duration_time", "duration")} {type === "steam" ? t("week") : t("hour")}
                  </Text>
                </Group>
                <div
                  style={{
                    width: "1px",
                    height: "11px",
                    background: "#62657D",
                  }}
                ></div>
                <Group spacing={xs ? 4 : 7}>
                  <Tag strokeWidth={1.75} color="#62657D" size={xs ? 18 : 20} />
                  <Text size={xs ? 14 : 16} weight={400} color="#3B3F5C" span>
                    {getKey("price", "price")} ₼
                  </Text>
                </Group>
              </Group>
            </Credentials>
          </ProgramBanner>
        </Grid.Col>
      </Grid>
    </section>
  );
};

export default ProgramInfo;

const ProgramInfoStyle = styled.div``;
const Info = styled.div`
  padding: 15px 15px 25px 15px;
  box-shadow: 0px 1px 10px 0px #0000001a;
  background: #fff;
  border-radius: 11px;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #dcdcdc;
  margin: 10px 0;
`;

const ProgramBanner = styled.div`
  // background: ${(props) => `url(${props.image}`});
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  height: 433px;
  width: 100%;
  position: relative;
  box-shadow: 0px 5.2px 10.4px 0px #d3dae2;
  border-radius: 11px;
`;
const Credentials = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0 0 11px 11px;
`;
