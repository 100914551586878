import React, { useContext } from "react";
import styled from "styled-components";
import { Button, Group, Stack, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import profileTop from "../../../Assets/SVGs/dashboard/profileTop.svg";
import { Mail, TrendingUp, Book, Atom, Users } from "tabler-icons-react";
import Interest from "../../../Assets/SVGs/dashboard/interest.svg";
import vector from "../../../Assets/SVGs/dashboard/Vector.svg";
import { AppContext } from "../../../Helpers/Context";
import defaultAvatar from "../../../Assets/SVGs/header/user.png";
import { useMediaQuery } from "@mantine/hooks";

const NavbarContent = ({ t }) => {
  const { user } = useContext(AppContext);
  const sizeXl = useMediaQuery("(min-width: 1460px)");

  const cards = [
    {
      title: "TIP",
      background: "#029199",
      vectorColor: "#D5E6AC",
      icon: TrendingUp,
    },
    {
      title: "Courses",
      background: "#FF7F51",
      vectorColor: "#FFC3A3",
      icon: Book,
    },
    {
      title: `${t("steam")}`,
      background: "#FFBF2A",
      vectorColor: "#FFECC0",
      icon: Atom,
    },
    {
      title: "Communities",
      background: "#A89ADE",
      vectorColor: "#D6CCFF",
      icon: Users,
    },
  ];

  return (
    <section style={{ width: "100%" }}>
      {user && (
        <ProfileInfo>
          <img
            style={{ borderRadius: "15px 15px 0 0", width: "100%" }}
            src={profileTop}
            alt="Profile-Top"
          />

          <img
            style={{ objectFit: "cover" }}
            className="profile-img"
            src={
              user?.profile_img &&  user?.profile_img.includes("user-profile") ?
              user?.profile_img : defaultAvatar
            }
            alt="user-img"
          />

          <Stack
            spacing={10}
            sx={{
              borderRadius: "0 0 15px 15px",
              padding: "60px 20px 20px 20px",
              marginTop: "-4px",
            }}
            align="center"
          >
            <Link to="/profile">
              <Title
                order={3}
                weight={700}
                color="#364152"
                size={sizeXl ? 20 : 18}
              >
                {user?.first_name} {user?.last_name}
              </Title>
            </Link>
            <Group noWrap spacing={5}>
              <Mail size={sizeXl ? 20 : 18} strokeWidth={1.5} color="#989898" />
              <Text size={sizeXl ? 16 : 14} weight={400} color="#989898">
                {user?.email}
              </Text>
            </Group>
            <div
              style={{
                width: "70%",
                height: "1px",
                backgroundColor: "#dcdcdc",
              }}
            ></div>

            <Group>
              <Text size={sizeXl ? 16 : 15} weight={400} color="#989898">
                SAT: N/a
              </Text>
              <Text size={sizeXl ? 16 : 15} weight={400} color="#989898">
                |
              </Text>
              <Text size={sizeXl ? 16 : 15} weight={400} color="#989898">
                IELTS: N/a
              </Text>
            </Group>
            <Button
              styles={{
                root: {
                  backgroundColor: "#D8FDFF",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#029199",
                  borderRadius: "15px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: "#D8FDFF",
                  },
                },
              }}
              sx={
                sizeXl
                  ? {
                      width: "100%",
                      height: "48px",
                    }
                  : {
                      width: "100%",
                      height: "42px",
                    }
              }
              leftIcon={<img src={Interest} alt="Interest" />}
            >
              {
                user?.fields && user?.fields.length > 0 ? t("interest") + " | " + user?.fields[0]?.field : user?.user_type?.type
              }
            </Button>
          </Stack>
        </ProfileInfo>
      )}
      <ProgramsInfo user={user}>
        {cards.map((card, index) => (
          <Link to={`/${ card.title === "Courses" ? "bootcamps" : card.title === `${t("steam")}` ? "steam" : card.title.toLowerCase()}`} key={index}>
            <CardContainer
              background={card.background}
              vectorColor={card.vectorColor}
            >
              <img className="vector-img" src={vector} alt="Vector" />
              <Stack spacing={12} style={{ padding: "0 25px" }} mt={15}>
                <Group sx={{ zIndex: 99999 }}>
                  <Text weight={700} size={sizeXl ? 16 : 14} color="#fff">
                    {card.title}
                  </Text>
                </Group>
                <Button
                  size="md"
                  radius={14}
                  leftIcon={<card.icon strokeWidth={1.5} />}
                  styles={() => ({
                    root: {
                      fontSize: "12px",
                      background: card.vectorColor,
                      color: "#364152",
                      cursor: "pointer",
                      padding: "0 20px",
                      height: "40px",
                      width: "100%",
                      "&:hover": {
                        opacity: 0.8,
                        background: card.vectorColor,
                      },
                    },
                  })}
                >
                  {t("go-to")}
                </Button>
              </Stack>
            </CardContainer>
          </Link>
        ))}
      </ProgramsInfo>
    </section>
  );
};

export default NavbarContent;

const ProfileInfo = styled.div`
  width: 100%;
  border-radius: 15px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  .profile-img {
    position: absolute;
    width: 94px;
    height: 94px;
    border-radius: 50px;
    border: 1px solid #c5c5c5;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .mantine-qo1k2.mantine-Button-label {
    overflow-x: scroll;
    margin-top: 3px;
  }
  @media (max-width: 1376px) {
    .profile-img {
      width: 84px;
      height: 84px;
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 99px;
  padding-bottom: 10px;
  border: 1px solid #c5c5c5;
  margin-top: 25px;
  border-radius: 11px;
  background-color: ${(props) => props.background};
  position: relative;
  z-index: 1;
  .vector-img {
    position: absolute;
    width: 131px;
    height: 68px;
    top: 0;
    right: 0;
    border-radius: 0 11px 0 0;
    z-index: 0;
    filter: ${(props) =>
      `brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%) opacity(${
        props.vectorColor === "#D5E6AC" ? "0.5" : "0.3"
      })`};
  }
  .mantine-Button-root {
    transition: opacity 0.3s ease;
}
  }
`;

const ProgramsInfo = styled.div`
  background-color: #fff;
  padding: 0.1px 25px 25px 25px;
  margin-top: ${(props) => (props.user ? "25px" : "0")};
  border-radius: 11px;
`;
