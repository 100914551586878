import { Group, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AlertCircle, ArrowUpRight } from "tabler-icons-react";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import axios from "../../Helpers/Axios";
import { showNotification } from "@mantine/notifications";

const MyTasks = ({ type }) => {
  const { id, unitId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columns, setColumns] = useState([[], [], []]);
  const xs = useMediaQuery("(max-width: 768px)");
  const tUnit = type === "steam" ? "week" : "section";
  const tSubUnit = type === "steam" ? "day" : "lesson";

  const { data: subUnits, isLoading } = useQuery({
    queryKey: ["subUnits", unitId],
    queryFn: async () => {
      const response = await axios.get(
        `${
          type === "steam" ? "steam/steam" : "bootcamp"
        }/${id}/${tUnit}/${unitId}/${tSubUnit}s`
      );
      return response.data.data;
    },
    enabled: !!unitId && !!id,
  });

  const handleDayClick = (subUnit, subUnitIndex, isCompleted) => {
    if (subUnits[subUnitIndex - 1]?.is_completed) {
      const isLastSubUnit = subUnitIndex === subUnits.length - 1;
      localStorage.setItem(`isLast${tSubUnit}`, isLastSubUnit);
      navigate(
        `/${type}/${id}/${tUnit}-pathway/${unitId}/${tSubUnit}/${subUnit.id}`
      );
    } else if (subUnitIndex === 0) {
      localStorage.setItem(`isLast${tSubUnit}`, "false");
      navigate(
        `/${type}/${id}/${tUnit}-pathway/${unitId}/${tSubUnit}/${subUnit.id}`
      );
    } else {
      showNotification({
        icon: <AlertCircle />,
        title: t("warning"),
        message: t(`warning_${tSubUnit}`),
        color: "red",
      });
    }
  };

  useEffect(() => {
    if (!subUnits) return;

    const columnCount = Math.min(subUnits.length, 3);
    const newColumns = Array.from({ length: columnCount }, () => []);
    
    subUnits.forEach((subUnit, index) => {
      newColumns[index % columnCount].push(subUnit);
    });
    
    setColumns(newColumns);
  }, [subUnits]);

  const getContentType = (subUnit) => {
    if (type === "steam") {
      return subUnit.tasks[0]?.task_name ? t("practical_work") : t("theoretical_material");
    }
    // Bootcamp üçün
    if (subUnit && subUnit.tasks[0]?.task_name) {
      return t("video_and_task");
    } else if (subUnit) {
      return t("video_lesson");
    } else if (!subUnit.tasks[0]?.task_name) {
      return t("theoretical_material");
    }
    return t("theoretical_material");
  };

  if (isLoading ) return <LottieLoading />;

  return (
    <TasksStyle>
      <Helmet>
        <title>{t("my_tasks_title")}</title>
        <meta name="description" content={t("my_tasks_description")} />
      </Helmet>
      {!xs && (
        <Group align="baseline" gap="20px">
          {columns.map((column, columnIndex) => (
            <WeekContainer type={type} key={columnIndex}>
              {column.map((subUnit, index) => {
                const subUnitIndex = columnIndex + index * 3;
                const isCompleted = subUnit.is_completed;
                return (
                  <DayContainer
                    type={type}
                    onClick={() =>
                      handleDayClick(subUnit, subUnitIndex, isCompleted)
                    }
                    key={subUnit.id}
                  >
                    <div style={{ padding: "10px 25px" }}>
                      <Text mb={15} size={18} weight={500} color="#121926">
                        {t(`${tSubUnit}`)} {subUnitIndex + 1}
                      </Text>
                      <div className="task-container">
                        <Group spacing={6}>
                          <div className="green-indicator"></div>
                          <Text size={12} weight={600} color="#9AA4B2">
                            {getContentType(subUnit)}
                          </Text>
                        </Group>
                        <Text my={10} size={16} weight={500} color="#000">
                          {subUnit.tasks[0]?.task_name}
                        </Text>
                        <Group style={{ flexWrap: "wrap" }} spacing={4}>
                          {subUnit.skills.map((tag) => (
                            <span key={tag.id || tag.name} className="tag">
                              #{tag.name}
                            </span>
                          ))}
                        </Group>
                        <Group align="center" position="apart">
                          <Group spacing={6} mt={10}>
                            <Text size={12} weight={400} color="#697586" span>
                              {t("duration")}:
                            </Text>
                            <Text size={12} weight={400} color="#000">
                              24 {t("hour")}
                            </Text>
                          </Group>
                          <ArrowUpRight
                            style={{ color: `#${type === "steam" ? "1570ef" : "000"}`, marginTop: "5px" }}
                          />
                        </Group>
                      </div>
                    </div>
                  </DayContainer>
                );
              })}
            </WeekContainer>
          ))}
        </Group>
      )}
      {xs && (
        <WeekContainer type={type}>
          {subUnits?.map((subUnit, index) => (
            <DayContainer
              type={type}
              onClick={() => handleDayClick(subUnit, index)}
              key={subUnit.id}
            >
              <div style={{ padding: "10px 25px" }}>
                <Text mb={15} size={18} weight={500} color="#121926">
                  {t("day")} {index + 1}
                </Text>
                <div className="task-container">
                  <Group spacing={6}>
                    <div className="green-indicator"></div>
                    <Text size={12} weight={600} color="#9AA4B2">
                      {getContentType(subUnit)}
                    </Text>
                  </Group>
                  <Text my={10} size={16} weight={500} color="#000">
                    {subUnit.tasks[0]?.task_name}
                  </Text>
                  <Group style={{ flexWrap: "wrap" }} spacing={4}>
                    {subUnit.skills.map((tag) => (
                      <span key={tag.id || tag.name} className="tag">
                        {tag.name}
                      </span>
                    ))}
                  </Group>
                  <Group align="center" position="apart">
                    <Group spacing={6} mt={10}>
                      <Text size={12} weight={400} color="#697586" span>
                        {t("duration")}:
                      </Text>
                      <Text size={12} weight={400} color="#000">
                        24 h
                      </Text>
                    </Group>
                    <ArrowUpRight
                      style={{ color: "#1570ef", marginTop: "5px" }}
                    />
                  </Group>
                </div>
              </div>
            </DayContainer>
          ))}
        </WeekContainer>
      )}
    </TasksStyle>
  );
};

export default MyTasks;

const TasksStyle = styled.div`
  max-width: 95%;
  margin: 20px auto;
`;
const WeekContainer = styled.div`
  border-radius: 20px;
  background-color: ${(props) => (props.type === "steam" ? "#e1e8ea" : "#FFD668")};
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 26%;

  @media (max-width: 768px) {
    width: 92%;
    margin: 0 auto;
  }
`;

const DayContainer = styled.div`
  cursor: pointer;
  width: 100%;
  .task-container {
    min-height: 150px;
    border-radius: 12px;
    border: 1px solid #cdd5df;
    padding: 20px 15px;
    background-color: #fff;
    .green-indicator {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: ${(props) => (props.type === "steam" ? "#17b26a" : "#FE6027")};
    }
    .tag {
      font-size: 14px;
      font-weight: 500;
      color: ${(props) => (props.type === "steam" ? "#029199" : "#FF9512")};
      margin-right: 5px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
