import React, { useState } from "react";
import styled from "styled-components";
import { Button, Group, Stack, Text } from "@mantine/core";
import previmg from "../../Assets/SVGs/steams/blog_img.svg"; // Preview image
import CustomCheckbox from "../../Components/StudyAbroad/Universities/Filter/CustomCheckbox";
import CustomFileInput from "../../Components/StudyAbroad/Apply/CustomFileInput";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import parse from "html-react-parser";
import link from "../../Assets/SVGs/steams/greenLink.svg";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import { useMediaQuery } from "@mantine/hooks";
import { Helmet } from "react-helmet-async";
import { formatDate2 } from "../../Helpers";
export const badgeData = [
  {
    color: "#6941C6",
    bgColor: "#F9F5FF",
  },
  {
    color: "#3538CD",
    bgColor: "#EEF4FF",
  },
  {
    color: "#C11574",
    bgColor: "#FDF2FA",
  },
];
const TaskBlog = ({ type }) => {
  const [isDone, setIsDone] = useState(false);
  const [showVideo, setShowVideo] = useState(false); // State to control video display
  const { t } = useTranslation();
  const xs = useMediaQuery("(max-width: 576px)");

  const { id, unitId, subUnitId } = useParams();
  const tUnit = type === "steam" ? "week" : "section";
  const tSubUnit = type === "steam" ? "day" : "lesson";
  const { data: subUnitContent, isLoading } = useQuery({
    queryKey: ["subUnitContent", id, unitId, subUnitId],
    queryFn: async () => {
      const response = await axios.get(
        `/${type === "steam" ? "steam/steam" : type}/${id}/${tUnit}/${unitId}/${
          tSubUnit === "day" ? "day" : "lessons"
        }/${subUnitId}/`
      );
      return response.data.data;
    },
  });

  const { data: subUnits,  } = useQuery({
    queryKey: ["subUnits", unitId],
    queryFn: async () => {
      const response = await axios.get(
        `${
          type === "steam" ? "steam/steam" : "bootcamp"
        }/${id}/${tUnit}/${unitId}/${tSubUnit}s`
      );
      return response.data.data;
    },
    enabled: !!unitId && !!id,
  });
  const nextSubUnit = subUnits?.find(subUnit => subUnit.id > subUnitId)

  const handleVideoClick = () => {
    setShowVideo(true); // Show video when play button is clicked
  };

  // Video URL-ni güvənli formata çevirmək üçün funksiya əlavə edin
  const getSecureUrl = (url) => {
    if (!url) return "";
    return url.replace("http://", "https://");
  };

  if (isLoading) return <LottieLoading />;

  return (
    <TaskBlogStyle xs={xs}>
      <Helmet>
        <title>{t("task_overview_title")}</title>
        <meta name="description" content={t("task_overview_description")} />
      </Helmet>
      <div className="blog-container">
        <div className="blog-content">
          <img
            style={{
              width: "100%",
              height: xs ? "224px" : "441px",
              objectFit: "fill",
              borderRadius: "11px",
            }}
            src={subUnitContent && subUnitContent[`${tSubUnit}_photo_output`]}
            alt="Blog img"
          />
          {subUnitContent?.tasks.length > 0 && (
            <Text
              mt={4}
              mb={6}
              size={xs ? 13 : 14}
              weight={500}
              color="#6941C6"
            >
              {formatDate2(
                subUnitContent?.tasks && subUnitContent?.tasks[0]?.created_at
              )}
            </Text>
          )}
          <Text mt={10} size={xs ? 18 : 24} weight={500} color="#1A1A1A">
            {subUnitContent?.tasks.length > 0
              ? subUnitContent?.tasks[0].task_name
              : t("theoretical_material")}
          </Text>
          <Group mt={15} mb={25}>
            {subUnitContent?.skills.map((data, index) => (
              <BadgeStyle
                key={index}
                color={badgeData[index].color}
                bg={badgeData[index].bgColor}
              >
                #{data.name}
              </BadgeStyle>
            ))}
          </Group>
          <Text
            style={{ lineHeight: "24px" }}
            size={xs ? 14 : 16}
            weight={400}
            color="#667085"
          >
            {parse(
              `${subUnitContent && subUnitContent[`${tSubUnit}_content`]}`
            )}
          </Text>
          {subUnitContent && subUnitContent?.files?.length > 0 && (
            <div style={{ margin: "0px 0 20px 0" }}>
              <Stack spacing={2}>
                {subUnitContent?.files?.map((file) => (
                  <div key={file?.id}>
                    <Text mb={10} size={16} weight={400}>
                      File
                    </Text>
                    <CustomFileInput
                      type="download"
                      to={file.file_field_output}
                      t={t}
                    />
                  </div>
                ))}
              </Stack>
            </div>
          )}
          {subUnitContent && subUnitContent[`${tSubUnit}_link`] && (
            <div style={{ margin: "20px 0" }}>
              <Text mb={10} size={16} weight={400}>
                Link
              </Text>
              <Group align="center" spacing={4}>
                <img src={link} alt="Link" />
                <a
                  style={{ color: "#00BD90", textDecoration: "underline" }}
                  href={subUnitContent && subUnitContent[`${tSubUnit}_link`]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {subUnitContent && subUnitContent[`${tSubUnit}_link`]}
                </a>
              </Group>
            </div>
          )}
          {/* Video section with preview image and play button */}
          {subUnitContent && subUnitContent[`${tSubUnit}_video_link`] && (
            <div style={{ margin: "20px 0" }}>
              <Text mb={10} size={16} weight={400}>
                Video
              </Text>
              {!showVideo ? (
                <div
                  className="prev-img"
                  onClick={handleVideoClick} // Start video on click
                >
                  <PlayButton>▶ {/* Simple Play Icon */}</PlayButton>
                </div>
              ) : (
                <div style={{ borderRadius: "11px", overflow: "hidden" }}>
                  <iframe
                    width="100%"
                    height={xs ? "224px" : "441px"}
                    src={getSecureUrl(subUnitContent[`${tSubUnit}_video_link`])}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {subUnitContent?.tasks.length > 0 && (
        <div className="check-container">
          <div className="check-content">
            <CustomCheckbox
              checked={isDone}
              onChange={() => setIsDone(!isDone)}
            />
            <Text span size={14} weight={500} color="#00BD90">
              {t("done")}
            </Text>
          </div>
        </div>
      )}
      {subUnitContent?.tasks.length > 0 ? (
        <div style={{ width: "96%", margin: "20px auto", textAlign: "center" }}>
          <Button
            disabled={!isDone}
            fullWidth
            mt={30}
            radius={10}
            component={Link}
            to={`/${type}/${id}/${tUnit}-pathway/${unitId}/${tSubUnit}/${subUnitId}/task`}
            styles={() => ({
              root: {
                backgroundColor: "#FE6027",
                color: "#fff",
                width: "100%",
                height: "56px",
                fontSize: "18px",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "#FE6027",
                },
              },
            })}
          >
            {t("start")}
          </Button>
        </div>) : 
      (  <div style={{ width: "96%", margin: "20px auto", textAlign: "center" }}>
        <Button
          fullWidth
          mt={30}
          radius={10}
          component={Link}
          to={`/${type}/${id}/${tUnit}-pathway/${unitId}/${tSubUnit}/${nextSubUnit?.id}`}
          styles={() => ({
            root: {
              backgroundColor: "#FE6027",
              color: "#fff",
              width: "100%",
              height: "56px",
              fontSize: "18px",
              fontWeight: "500",
              "&:hover": {
                backgroundColor: "#FE6027",
              },
            },
          })}
        >
          {t(`next_${tSubUnit}`)}
        </Button>
      </div> 
      )}
    </TaskBlogStyle>
  );
};

export default TaskBlog;

const TaskBlogStyle = styled.div`
  background-color: #eff3f4;
  padding: 25px 0px;

  .prev-img {
    position: relative;
    width: 100%;
    height: ${props => props.xs ? '224px' : '441px'};
    border-radius: 11px;
    background-image: url(${previmg});
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &::after {
    }
  }

  .blog-container {
    max-width: 96%;
    margin: 0px auto;
    background-color: white;
    border-radius: 13px;
    .blog-content {
      padding: 30px;
    }
  }
  .check-container {
    width: 96%;
    background-color: white;
    border-radius: 13px;
    margin: 25px auto;
    .check-content {
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    .blog-content {
      padding: 15px 10px !important;
    }
  }
`;

export const BadgeStyle = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.bg};
  border-radius: 16px;
  padding: 2px 16px;
  font-size: 14px;
  font-weight: 500;
`;

// PlayButton styled component for play icon positioning
const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
`;
