import { useEffect } from 'react';

function VersionChecker() {
  useEffect(() => {
    const checkVersion = () => {
      try {
        // Meta tag-dən cari versiyanı alırıq
        const metaVersion = document.querySelector('meta[name="app-version"]')?.content;
        // Local Storage-də saxlanılan əvvəlki versiyanı yoxlayırıq
        const storedVersion = localStorage.getItem('app-version');
        
        // Əgər versiyalar fərqlidirsə
        if (metaVersion && storedVersion && metaVersion !== storedVersion) {

          
            // Cache və Service Worker təmizləmə
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker.getRegistrations().then((registrations) => {
                registrations.forEach((registration) => registration.unregister());
              });
            }
            
            if (window.caches) {
              caches.keys().then((keys) => {
                keys.forEach((key) => caches.delete(key));
              });
            }
            
            // Yeni versiyanı yadda saxlayırıq və səhifəni yeniləyirik
            localStorage.setItem('app-version', metaVersion);
            window.location.reload(true);
        } else if (metaVersion && !storedVersion) {
          if (window.caches) {
            caches.keys().then((keys) => {
              keys.forEach((key) => caches.delete(key));
            });
          }
          // İlk dəfə yüklənirsə, versiyanı saxlayırıq
          localStorage.setItem('app-version', metaVersion);
          window.location.reload(true);

        }
      } catch (error) {
        console.error('Versiya yoxlanışı zamanı xəta:', error);
      }
    };

    // İlk yoxlama
    checkVersion();

    // 5 dəqiqədən bir yoxlama
    const interval = setInterval(checkVersion, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return null;
}

export default VersionChecker;