import React from "react";
import { Button } from "@mantine/core";

const CustomButton = ({
  children,
  onClick,
  loading,
  variant = "filled",
  color,
  type,
  xs,
  width,
  height,
  ...props
}) => {
  const buttonColor =
    type === "steam" ? "#029199" : type === "bootcamp" ? "#FE6027" : color;

  return (
    <Button
      radius={10}
      variant={variant}
      onClick={onClick}
      loading={loading}
      styles={(theme) => ({
        root: {
          width: width || (xs ? "100%" : "48%"),
          height: height || "45px",
          background: variant === "filled" ? buttonColor : "#fff",
          color: variant === "filled" ? "#fff" : buttonColor,
          fontSize: "15px",
          fontWeight: 700,
          border: variant !== "filled" ? `1px solid ${buttonColor}` : "none",
          "&:hover": {
            background: variant === "filled" ? buttonColor : "#fff",
            border: variant !== "filled" ? `1px solid ${buttonColor}` : "none",
          },
        },
      })}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
