import React from 'react';
import { Button, FileInput } from '@mantine/core';
import { Download, Paperclip } from 'tabler-icons-react';

const CustomFileInput = ({
  t, 
  label, 
  name, 
  setValue, 
  addFileToState, 
  hiddenInputId,
  type,
  to,
  setShowAddButton,
  disabled,
  error,
  watch,
  setWatch
}) => {
  const handleFileChange = (event) => {
    const file = event;
    if (addFileToState) {
      addFileToState(file);
    } else {
      setValue(name, file);
      setWatch && setWatch(file);
      setShowAddButton && setShowAddButton(prev => true)
    }
  };

  return (
    <div>
      <FileInput
        radius={10}
        size="lg"
        placeholder={t('pdf-file')}
        multiple={name?.includes('steam-file') ? true : false}
        label={label}
        withAsterisk={(name?.includes('steam-file') || name === "additional-document" || name === "certificate_graduation") ? false : true}
        style={{ fontSize: "14px",width : "100%" }}
        styles={() => {
          return {
            label: {
              fontSize: "14px",
              fontWeight: 500,
            },
            input: {
              fontSize: "15px",
              border: watch ? '1px solid #ced4da' : error ? '1px solid #fa5252' : '1px solid #ced4da',
            },
            error: {
              fontSize: "14px",
            },
            rightSection: {
              width: "auto",
            }, 
          };
        }}
        onChange={handleFileChange}
        disabled={disabled}
        error={watch ? null : error}
        value={watch}
        
        rightSection={
          type !== "download"?
          <Button
            variant="outline"
            size="sm"
            radius={10}
            className="upload-btn"
            style={{
              right : "10px"
              
            }}
            rightIcon={
              <Paperclip
                style={{ rotate: "-45deg" }}
                size={12}
                strokeWidth={1.5}
                color={"black"}
              />
            }
            onClick={(e) => {
              e.preventDefault();
              if (label === 'Additional document') {
                document.querySelector(`#${hiddenInputId}`).click();
              } else {
                document.querySelector('input[type="file"]').click();
              }
            }}
          >
            {t("upload-file")}
          </Button> :

          <Button
          rightIcon={<Download strokeWidth={1.5} color='#029199' />}
          style={{
            border : '1px solid #029199',
            background : "#EFF8FF",
            color: "#029199",
            fontSize: "16px",
            fontWeight: 500,
            width : "100%",
            right : "10px"
            
          }}
          className='download'
          radius={10}
          >
            <a style={{textDecoration:"none",color:"#029199"}} href={to} target='_blank' rel="noreferrer" >
            {t("download")}
            </a>
          </Button>

        }
      />
    </div>
  );
};

export default CustomFileInput;
