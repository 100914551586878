import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import axios from "../../Helpers/Axios";
import Banner from "../../Components/StudyAbroad/Banner";
import TabMenu from "../../Components/StudyAbroad/TabMenu";
import agentBanner from '../../Assets/SVGs/university/agentBanner.png'
import agentIcon from '../../Assets/SVGs/university/agentIcon.svg'
import { useTranslation } from "react-i18next";

const SingleAgent = () => {
  const { aId } = useParams();
  const { t } = useTranslation();

  const { data: agent } = useQuery({
    queryKey: ["agent", aId],
    queryFn: async () => {
      const { data } = await axios.get(`/agent/${aId}/`);
      return data;
    }
  });

  return (
    <>
      <Helmet>
        <title>{t('single_agent_page_title', { agentName: agent?.first_name || t('unknown_agent') })}</title>
        <meta 
          name="description" 
          content={t('single_agent_page_description', { 
            agentName: agent?.first_name || t('unknown_agent'),
            description: agent?.services || ''
          })} 
        />
      </Helmet>

      <Banner props={{ t, type: "agent", agentIcon, bannerImg: agentBanner, agentId: aId, agent }} />
      <Container>
        <TabMenu props={{ t, type: "agent", aId, agent }} />
      </Container>
    </>
  );
};

export default SingleAgent;

const Container = styled.div`
  max-width: 92%;
  margin: 40px auto 80px auto;
  min-height: 50vh;
`;
