export const pathwayThemes = {
  steam: {
    primary: '#029199',
    secondary: '#00BD90',
    gradient: {
      start: '#029199',
      end: '#00BD90'
    }
  },
  bootcamp: {
    primary: '#FF8F67',
    secondary: '#FFCA3B',
    gradient: {
      start: '#FF8F67',
      end: '#FFCA3B'
    }
  }
}; 