import React, { useContext, useRef, useState } from "react";
import {
  Button,
  Grid,
  Group,
  Menu,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  BellRinging,
  ChevronDown,
  ChevronUp,
  Language,
  Menu2,
} from "tabler-icons-react";
import subsIcon from "../../../Assets/SVGs/subscription-icon.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import styled from "styled-components";
import logo from "../../../Assets/SVGs/logo/bgLogo.svg";
import NewSearchBar from "./SearchBar";
import SideBar from "../Sidebar/index";
import { useClickAway } from "react-use";
import { AppContext } from "../../../Helpers/Context";
import User from "../../../Assets/SVGs/dashboard/User.svg";
import Portal from "../../../Assets/SVGs/dashboard/Portal.svg";
import Settings from "../../../Assets/SVGs/dashboard/Settings.svg";
import Logout from "../../../Assets/SVGs/dashboard/Logout.svg";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import { useLanguage } from "../../../Helpers/Contexts/LanguageContext";
import { cookieUtils } from "../../../utils/cookieUtils";
import defaultAvatar from "../../../Assets/SVGs/header/user.png";
const UserHeader = () => {
  const sizeXs = useMediaQuery("(max-width: 576px)");
  const { user } = useContext(AppContext);
  const [currentNav, setCurrentNav] = useState();

  const [subsVisible, setSubsVisible] = useState(true);
  const { setIsChangedLocalStorage } = useLanguage();

  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("tsklng") || "az"
  );
  const { t, i18n } = useTranslation();

  const langs = [
    {
      iso: "Az",
      click: () => changeLang("az"),
    },
    {
      iso: "En",
      click: () => changeLang("en"),
    },
    {
      iso: "Ru",
      click: () => changeLang("ru"),
    },
    {
      iso: "Tr",
      click: () => changeLang("tr"),
    },
  ];

  function changeLang(lng) {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    localStorage.setItem("tsklng", lng);
    setIsChangedLocalStorage((prev) => !prev);
  }

  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));
  const toggleSidebar = () => setOpen((prev) => !prev);

  const cookie = new Cookies();

  // const { mutate: logOut } = useMutation(
  //   ["logout"],
  //   async (e) => {
  //     return await axios.post("/api/v1/logout/", e);
  //   },
  //   {
  //     onSuccess: () => {
  //       cookie.remove("access", { path: "/" });
  //       cookie.remove("refresh", { path: "/" });
  //       setUser(null);
  //     },
  //     onError: (err) => {
  //       console.log("err", err);
  //     },
  //   }
  // );

  function logout() {
    localStorage.setItem("taskooluser", null);
    cookieUtils.clearAuthCookies();
    window.location = "/";
  }

  const sizeXl = useMediaQuery("(min-width: 1440px)");
  const sizeLg = useMediaQuery("(min-width: 1340px)");
  const sizeSm = useMediaQuery("(max-width: 1250px)");

  const { pathname } = useLocation();
  return (
    <>
      {!sizeXs && (
        <HeaderContentContainer path={pathname} selectedLang={selectedLang}>
          <div style={{ maxWidth: "96.5%", margin: "0 auto" }}>
            <Grid align="center" sx={{ marginTop: "0px" }}>
              <Grid.Col xs={6}>
                <Group
                  spacing={sizeSm ? 60 : 0}
                  position={sizeSm ? "" : "apart"}
                >
                  {sizeSm && <Menu2 onClick={toggleSidebar} />}

                  <Link to="/home">
                    <img src={logo} alt="Logo" />
                  </Link>

                  {!sizeSm && (
                    <Group
                      className="nav"
                      position="right"
                      spacing={
                        selectedLang === "ru"
                          ? 0
                          : sizeXl
                          ? 25
                          : sizeLg
                          ? 20
                          : 10
                      }
                    >
                      <Link to="/steam" style={{ textDecoration: "none" }}>
                        <Group className="nav-link" position="right">
                          <Title
                            order={5}
                            size={17}
                            weight="bold"
                            color="#364152"
                            className="nav-title"
                          >
                            {t("steam")}
                          </Title>
                        </Group>
                      </Link>

                      <Menu
                        offset={-7}
                        trigger="hover"
                        openDelay={100}
                        closeDelay={200}
                      >
                        <Menu.Target>
                          <Link to="/universities">
                            <Group
                              className="nav-link"
                              noWrap
                              spacing={4}
                              position="right"
                              onMouseEnter={() => setCurrentNav("university")}
                              onMouseLeave={() => setCurrentNav("")}
                              sx={
                                currentNav === "university" && {
                                  backgroundColor: "#029199",
                                  transition: "all 1s linear",
                                  boxShadow: "0 4px 15px 0 rgba(0,0,0,0.2)",
                                }
                              }
                            >
                              <Title
                                order={5}
                                size={17}
                                weight="bold"
                                style={
                                  currentNav === "university"
                                    ? { color: "#CCF075" }
                                    : { color: "#364152" }
                                }
                                className="nav-title"
                              >
                                {t("university")}
                              </Title>
                              {currentNav === "university" ? (
                                <ChevronUp
                                  size={17}
                                  strokeWidth={2.5}
                                  style={
                                    currentNav === "university"
                                      ? { color: "#CCF075", marginTop: "4px" }
                                      : { color: "#364152", marginTop: "4px" }
                                  }
                                />
                              ) : (
                                <ChevronDown
                                  size={17}
                                  strokeWidth={2.5}
                                  style={
                                    currentNav === "university"
                                      ? { color: "#CCF075", marginTop: "4px" }
                                      : { color: "#364152", marginTop: "4px" }
                                  }
                                />
                              )}
                            </Group>
                          </Link>
                        </Menu.Target>

                        <Menu.Dropdown
                          onMouseEnter={() => setCurrentNav("university")}
                          onMouseLeave={() => setCurrentNav("")}
                          sx={{ border: "none" }}
                          className="university-dropdown"
                        >
                          <Stack spacing={10} align="center">
                            <Link to="/universities">
                              <Text
                                sx={{
                                  cursor: "pointer",
                                  maxWidth: "80%",
                                  textAlign: "center",
                                  margin: "0 auto",
                                }}
                                size={16}
                                weight={500}
                                color="#fff"
                              >
                                {t("study-abroad")}
                              </Text>
                            </Link>
                            {selectedLang === "az" && (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "1px",
                                    backgroundColor: "#CCF075",
                                  }}
                                ></div>
                                <Link to="/tip">
                                  <Text
                                    sx={{
                                      cursor: "pointer",
                                      textAlign: "center",
                                    }}
                                    size={16}
                                    weight={500}
                                    color="#fff"
                                  >
                                    TIP - <br /> Ixtisaslaşma
                                  </Text>
                                </Link>
                              </>
                            )}
                          </Stack>
                        </Menu.Dropdown>
                      </Menu>

                      <Link to="/communities">
                        <Group className="nav-link" position="right">
                          <Title
                            order={5}
                            size={17}
                            className="nav-title"
                            weight="bold"
                            color="#364152"
                          >
                            {t("community")}
                          </Title>
                        </Group>
                      </Link>

                      <Menu
                        offset={-6}
                        trigger="hover"
                        openDelay={100}
                        closeDelay={200}
                      >
                        <Menu.Target>
                          <Group
                            className="nav-link"
                            noWrap
                            spacing={4}
                            position="right"
                            onMouseEnter={() => setCurrentNav("study")}
                            onMouseLeave={() => setCurrentNav("")}
                            sx={
                              currentNav === "study" && {
                                backgroundColor: "#029199",
                                transition: "all 1s linear",
                                boxShadow: "0 4px 15px 0 rgba(0,0,0,0.2)",
                              }
                            }
                          >
                            <Title
                              order={5}
                              size={17}
                              weight="bold"
                              style={
                                currentNav === "study"
                                  ? { color: "#CCF075" }
                                  : { color: "#364152" }
                              }
                              className="nav-title"
                            >
                              {t("study")}
                            </Title>
                            {currentNav === "study" ? (
                              <ChevronUp
                                size={18}
                                strokeWidth={2.5}
                                style={
                                  currentNav === "study"
                                    ? { color: "#CCF075", marginTop: "4px" }
                                    : { color: "#364152", marginTop: "4px" }
                                }
                              />
                            ) : (
                              <ChevronDown
                                size={18}
                                strokeWidth={2.5}
                                style={
                                  currentNav === "study"
                                    ? { color: "#CCF075", marginTop: "4px" }
                                    : { color: "#364152", marginTop: "4px" }
                                }
                              />
                            )}
                          </Group>
                        </Menu.Target>

                        <Menu.Dropdown
                          onMouseEnter={() => setCurrentNav("study")}
                          onMouseLeave={() => setCurrentNav("")}
                          sx={{ border: "none" }}
                          className="study-dropdown"
                        >
                          <Stack spacing={10} align="center">
                            <Link to="/bootcamps">
                              <Text
                                sx={{ cursor: "pointer" }}
                                size={14}
                                weight={500}
                                color="#fff"
                              >
                                {t("course")}
                              </Text>
                            </Link>
                            <div
                              style={{
                                width: "100%",
                                height: "1px",
                                backgroundColor: "#CCF075",
                              }}
                            ></div>
                            <Link to="/olympics">
                              <Text
                                sx={{ cursor: "pointer" }}
                                size={14}
                                weight={500}
                                color="#fff"
                              >
                                {t("olympic")}
                              </Text>
                            </Link>
                          </Stack>
                        </Menu.Dropdown>
                      </Menu>
                    </Group>
                  )}
                </Group>
              </Grid.Col>
              <Grid.Col xs={6}>
                <Group position="right" spacing={10}>
                  {subsVisible && (
                    <Button
                      component={Link}
                      to="/subscribtion"
                      styles={{
                        root: {
                          "&:hover": {
                            backgroundColor: "#CCF075",
                          },
                        },
                      }}
                      leftIcon={
                        subsVisible && <img src={subsIcon} alt="Subs-Icon" />
                      }
                      sx={
                        subsVisible
                          ? {
                              background: "#CCF075",
                              color: "#364152",
                              fontSize: "16px",
                              fontWeight: "500",
                              marginRight: "15px",
                              width: "170px",
                              height: "48px",
                              borderRadius: "14px",
                            }
                          : {
                              width: "0px",
                              height: "0px",
                              margin: "0px",
                              padding: "0px",
                            }
                      }
                    >
                      {t("subscription")}
                    </Button>
                  )}
                  <div>
                    <NewSearchBar props={{ subsVisible, setSubsVisible }} />
                  </div>

                  <Group noWrap spacing={8}>
                    <div
                      style={{
                        width: "1px",
                        height: "18px",
                        background: "#364152",
                      }}
                    ></div>
                    <Menu
                      offset={-4}
                      trigger="hover"
                      openDelay={100}
                      closeDelay={200}
                    >
                      <Menu.Target>
                        <Group
                          className="language"
                          noWrap
                          spacing={4}
                          position="right"
                          onMouseEnter={() => setCurrentNav("language")}
                          onMouseLeave={() => setCurrentNav("")}
                          sx={
                            currentNav === "language" && {
                              backgroundColor: "#029199",
                              transition: "all 1s linear",
                              boxShadow: "0 4px 15px 0 rgba(0,0,0,0.2)",
                            }
                          }
                        >
                          <Language
                            style={
                              currentNav === "language"
                                ? { color: "#CCF075" }
                                : { color: "#364152" }
                            }
                            size={16}
                            strokeWidth={2}
                          />
                          <Title
                            style={
                              currentNav === "language"
                                ? {
                                    color: "#CCF075",
                                    textTransform: "capitalize",
                                  }
                                : {
                                    color: "#364152",
                                    textTransform: "capitalize",
                                  }
                            }
                            size={18}
                            weight={500}
                            color="#364152"
                          >
                            {selectedLang}
                          </Title>
                          {currentNav === "language" ? (
                            <ChevronUp
                              style={
                                currentNav === "language"
                                  ? { color: "#CCF075", marginTop: "4px" }
                                  : { color: "#364152", marginTop: "4px" }
                              }
                              size={18}
                              strokeWidth={2.5}
                            />
                          ) : (
                            <ChevronDown
                              style={
                                currentNav === "language"
                                  ? { color: "#CCF075", marginTop: "4px" }
                                  : { color: "#364152", marginTop: "4px" }
                              }
                              size={18}
                              strokeWidth={2.5}
                            />
                          )}
                        </Group>
                      </Menu.Target>

                      <Menu.Dropdown
                        onMouseEnter={() => setCurrentNav("language")}
                        onMouseLeave={() => setCurrentNav("")}
                        sx={{ border: "none" }}
                        className="language-dropdown"
                      >
                        <Stack spacing={10} align="center">
                          {langs.map((item, index) => (
                            <React.Fragment key={index}>
                              <Text
                                key={index}
                                sx={{ cursor: "pointer" }}
                                size={16}
                                weight={500}
                                color="#fff"
                                onClick={item.click}
                              >
                                {item.iso}
                              </Text>
                              {index !== langs.length - 1 && (
                                <div
                                  style={{
                                    width: "150%",
                                    height: `${
                                      item.iso === "en" ? "0.5px" : "1.5px"
                                    }`,
                                    backgroundColor: "#CCF075",
                                  }}
                                ></div>
                              )}
                            </React.Fragment>
                          ))}
                        </Stack>
                      </Menu.Dropdown>
                    </Menu>
                    <div
                      style={{
                        width: "1px",
                        height: "18px",
                        background: "#364152",
                      }}
                    ></div>
                  </Group>

                  <div
                    style={{
                      border: "1px solid #D6D5D5",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BellRinging strokeWidth={1.5} />
                  </div>

                  {/* profile */}
                  <Menu
                    offset={-4}
                    trigger="hover"
                    openDelay={100}
                    closeDelay={200}
                  >
                    <Menu.Target>
                      <Group
                        className="profile"
                        noWrap
                        position="apart"
                        onMouseEnter={() => setCurrentNav("profile")}
                        onMouseLeave={() => setCurrentNav("")}
                        sx={
                          currentNav === "profile"
                            ? {
                                boxShadow: "0 4px 15px 0 rgba(0,0,0,0.2)",
                                width: "186px",
                                transition: "all 0.3s ease-in-out",
                                padding: "0 10px",
                              }
                            : {
                                width: "109px",
                                transition: "all 0.3s ease-in-out",
                              }
                        }
                      >
                        <Group spacing={8}>
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50px",
                              objectFit: "cover"
                            }}
                            src={
                              user?.profile_img &&  user?.profile_img.includes("user-profile") ?
                              user?.profile_img : defaultAvatar
                            }
                            alt="User-Img"
                          />
                          <Text size={18} weight={500} color="#364152">
                            Me
                          </Text>
                        </Group>

                        {currentNav === "profile" ? (
                          <ChevronUp
                            style={
                              currentNav === "profile"
                                ? { color: "#364152", marginTop: "4px" }
                                : { color: "#fff", marginTop: "4px" }
                            }
                            size={18}
                            strokeWidth={2.5}
                          />
                        ) : (
                          <ChevronDown
                            style={
                              currentNav === "profile"
                                ? { color: "#fff", marginTop: "4px" }
                                : { color: "#364152", marginTop: "4px" }
                            }
                            size={18}
                            strokeWidth={2.5}
                          />
                        )}
                      </Group>
                    </Menu.Target>

                    <Menu.Dropdown
                      onMouseEnter={() => setCurrentNav("profile")}
                      onMouseLeave={() => setCurrentNav("")}
                      sx={{ border: "none" }}
                      className="profile-dropdown"
                    >
                      <Stack spacing={10} align="baseline">
                        <Link to="/profile">
                          <Group>
                            <img src={User} alt="user" />
                            <Text color="#364152">My Profile</Text>
                          </Group>
                        </Link>
                        <Link to="/universities/portal">
                          <Group>
                            <img src={Portal} alt="portal" />
                            <Text color="#364152">University Portal</Text>
                          </Group>
                        </Link>
                        <Link to="/settings">
                          <Group>
                            <img src={Settings} alt="settings" />
                            <Text color="#364152">Settings</Text>
                          </Group>
                        </Link>
                        <div
                          style={{
                            width: "100%",
                            height: "1px",
                            background: "#eeeeee",
                          }}
                        ></div>
                        <Group style={{ cursor: "pointer" }} onClick={logout}>
                          <img src={Logout} alt="log-out" />
                          <Text color="#364152">Log out</Text>
                        </Group>
                      </Stack>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
              </Grid.Col>
            </Grid>
          </div>
          {open && (
            <SideBar
              props={{
                ref,
                currentNav,
                setCurrentNav,
                toggleSidebar,
                open,
                setOpen,
                main: true,
              }}
            />
          )}
        </HeaderContentContainer>
      )}

      {sizeXs && (
        <HeaderMobileContainer path={pathname} selectedLang={selectedLang}>
          <div style={{ maxWidth: "90%", margin: "0 auto" }}>
            <Grid align="center">
              <Grid.Col span={1}>
                <Menu2 onClick={toggleSidebar} />
              </Grid.Col>
              <Grid.Col span={11}>
                <Group position="right">
                  {currentNav !== "profile" && (
                    <Group noWrap spacing={8}>
                      <Menu
                        offset={-4}
                        trigger="hover"
                        openDelay={100}
                        closeDelay={200}
                      >
                        <Menu.Target>
                          <Group
                            className="language"
                            noWrap
                            spacing={4}
                            position="right"
                            onMouseEnter={() => setCurrentNav("language")}
                            onMouseLeave={() => setCurrentNav("")}
                            sx={
                              currentNav === "language" && {
                                backgroundColor: "#1a5059",
                                transition: "all 1s linear",
                              }
                            }
                          >
                            <Language
                              style={
                                currentNav === "language"
                                  ? { color: "#fff" }
                                  : { color: "#364152" }
                              }
                              size={16}
                              strokeWidth={2}
                            />
                            <Title
                              style={
                                currentNav === "language"
                                  ? {
                                      color: "#fff",
                                      textTransform: "capitalize",
                                    }
                                  : {
                                      color: "#364152",
                                      textTransform: "capitalize",
                                    }
                              }
                              size={18}
                              weight={500}
                              color="#364152"
                            >
                              {selectedLang}
                            </Title>
                            {currentNav === "language" ? (
                              <ChevronUp
                                style={
                                  currentNav === "language"
                                    ? { color: "#fff", marginTop: "4px" }
                                    : { color: "#364152", marginTop: "4px" }
                                }
                                size={18}
                                strokeWidth={2.5}
                              />
                            ) : (
                              <ChevronDown
                                style={
                                  currentNav === "language"
                                    ? { color: "#fff", marginTop: "4px" }
                                    : { color: "#364152", marginTop: "4px" }
                                }
                                size={18}
                                strokeWidth={2.5}
                              />
                            )}
                          </Group>
                        </Menu.Target>

                        <Menu.Dropdown
                          onMouseEnter={() => setCurrentNav("language")}
                          onMouseLeave={() => setCurrentNav("")}
                          sx={{ border: "none" }}
                          className="language-dropdown"
                        >
                          <Stack spacing={10} align="center">
                            {langs.map((item, index) => (
                              <React.Fragment key={index}>
                                <Text
                                  key={index}
                                  sx={{ cursor: "pointer" }}
                                  size={16}
                                  weight={500}
                                  color="#fff"
                                  onClick={item.click}
                                >
                                  {item.iso}
                                </Text>
                                {index !== langs.length - 1 && (
                                  <div
                                    style={{
                                      width: "150%",
                                      height: `${
                                        item.iso === "en" ? "0.5px" : "1.5px"
                                      }`,
                                      backgroundColor: "#CCF075",
                                    }}
                                  ></div>
                                )}
                              </React.Fragment>
                            ))}
                          </Stack>
                        </Menu.Dropdown>
                      </Menu>
                      <div
                        style={{
                          width: "1px",
                          height: "18px",
                          background: "#364152",
                        }}
                      ></div>
                    </Group>
                  )}

                  <div
                    style={{
                      border: "1px solid #D6D5D5",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BellRinging strokeWidth={1.5} />
                  </div>

                  <Menu
                    offset={-4}
                    trigger="hover"
                    openDelay={100}
                    closeDelay={200}
                  >
                    <Menu.Target>
                      <Group
                        className="profile"
                        noWrap
                        align="center"
                        onMouseEnter={() => setCurrentNav("profile")}
                        onMouseLeave={() => setCurrentNav("")}
                        sx={
                          currentNav === "profile"
                            ? {
                                boxShadow: "0 4px 15px 0 rgba(0,0,0,0.2)",
                                width: "45%",
                                transition: "all 0.3s ease-in-out",
                                padding: "0 10px",
                                background: "#fff",
                              }
                            : {
                                width: "109px",
                                transition: "all 0.3s ease-in-out",
                              }
                        }
                      >
                        <Group spacing={8}>
                          <img
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50px",
                            }}
                            src={  user?.profile_img &&  user?.profile_img.includes("user-profile") ?
                              user?.profile_img : defaultAvatar}
                            alt="User-Img"
                          />
                          <Text size={18} weight={500} color="#364152">
                            Me
                          </Text>
                        </Group>

                        {currentNav === "profile" ? (
                          <ChevronUp
                            style={
                              currentNav === "profile"
                                ? { color: "#364152" }
                                : { color: "#fff" }
                            }
                            size={18}
                            strokeWidth={2.5}
                          />
                        ) : (
                          <ChevronDown
                            style={
                              currentNav === "profile"
                                ? { color: "#fff" }
                                : { color: "#364152" }
                            }
                            size={18}
                            strokeWidth={2.5}
                          />
                        )}
                      </Group>
                    </Menu.Target>

                    <Menu.Dropdown
                      onMouseEnter={() => setCurrentNav("profile")}
                      onMouseLeave={() => setCurrentNav("")}
                      sx={{ border: "none" }}
                      className="profile-dropdown"
                    >
                      <Stack spacing={10} align="baseline">
                        <Link to="/profile">
                          <Group>
                            <img
                              style={{ width: "14px" }}
                              src={User}
                              alt="user"
                            />
                            <Text size={11.5} color="#364152">
                              My Profile
                            </Text>
                          </Group>
                        </Link>
                        <Link to="/universities/portal">
                          <Group>
                            <img
                              style={{ width: "14px" }}
                              src={Portal}
                              alt="portal"
                            />
                            <Text size={11.5} color="#364152">
                              University Portal
                            </Text>
                          </Group>
                        </Link>
                        <Link to="/settings">
                          <Group>
                            <img
                              style={{ width: "14px" }}
                              src={Settings}
                              alt="settings"
                            />
                            <Text size={11.5} color="#364152">
                              Settings
                            </Text>
                          </Group>
                        </Link>
                        <div
                          style={{
                            width: "100%",
                            height: "1px",
                            background: "#eeeeee",
                          }}
                        ></div>
                        <Group style={{ cursor: "pointer" }} onClick={logout}>
                          <img
                            style={{ width: "14px" }}
                            src={Logout}
                            alt="log-out"
                          />
                          <Text size={12} color="#364152">
                            Log out
                          </Text>
                        </Group>
                      </Stack>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
              </Grid.Col>
            </Grid>
          </div>
          {open && (
            <SideBar
              props={{
                ref,
                currentNav,
                setCurrentNav,
                toggleSidebar,
                open,
                setOpen,
                main: true,
                subsIcon,
              }}
            />
          )}
        </HeaderMobileContainer>
      )}
    </>
  );
};

export default UserHeader;

const HeaderContentContainer = styled.div`
margin-bottom:${(props) =>
  props.path === "/" ||
  props.path?.includes("tip") ||
  props.path?.includes("overview") ||
  props.path?.includes("week-pathway") ||
  props.path?.includes("section-pathway") ||
  props.path?.includes("task-reviews") ||
  props.path?.includes("universities") ||
  props.path?.includes("payment") ||
  props.path?.includes("agents")
    ? "0px"
    : "20px"};
background-color: #fff;
padding : 10px 0px;
position : sticky;
top : 0;
z-index :99;
box-shadow : 0 4px 15px 0 rgba(0, 0, 0, 0.1);

  .mantine-Menu-dropdown {
    background-color: #00A3AC;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 14px 14px;
    box-shadow : 0 4px 15px 0 rgba(0,0,0,0.2);
  }
  .study-dropdown {
    padding: ${(props) =>
      props.selectedLang === "az"
        ? "14px 4px"
        : props.selectedLang === "en"
        ? "14px 16px"
        : props.selectedLang === "ru"
        ? "14px 16px"
        : "14px 6px"} ;
    height: 108px !important;
  
  }

  .language-dropdown {
    padding: ${(props) =>
      props.selectedLang === "tr" ? "14px 26px" : "14px 28px"} ;
  }
  .university-dropdown {
    padding: ${(props) =>
      props.selectedLang === "az" ? "16px 4px !important" : "0px 0px"} ;
        height: ${(props) => (props.selectedLang === "az" ? "148px" : "90px")};
        max-width: ${(props) =>
          props.selectedLang === "az"
            ? "100%"
            : props.selectedLang === "ru"
            ? "130px"
            : "110px"};
  }
  .profile{
  height: 51px;
  border-radius: 14px;
  z-index: 99999;
  transition : all 0.3s ease-in-out;
   &:hover {
  background : #fff;
   box-shadow : 0 4px 15px 0 rgba(0,0,0,0.2);
   width : 186px;
   transition : all 0.3s ease-in-out;
   padding : 0 10px;

   }
  }
  .profile-dropdown {
    background-color: #fff !important;
    padding: 12px;
  }
  .search-box {
    display: flex;
    align-items: center;
    position: relative;

    .search-list {
      z-index: 99;
      position: absolute;
      top: 55px;
      left: -4px;
      width: 186px;
      background: #fff;
      border-radius: 8px;
      max-height: 200px;
      padding: 6px 10px;
      border: 1px solid #ccc;
      list-style: none;
      font-size: 12px;
      font-family: "Inter";
      cursor: pointer;

      li {
        padding: 5px;
        border-radius: 5px;
        &:hover {
          background: #eee;
        }
      }
    }

    .icon-item {
      background: none;
      position: absolute;
      right: 8px;
      bottom: 6px;
    }

    &:hover {
      .search-input {
        width: 170px;
        border: 1px solid #cdd5df;
        opacity: 1;
      }
    }

    .search-input {
      opacity: 0;
      width: 0px;
      overflow: hidden;
      transition: 0.3s;
      cursor: pointer;
      border: none;
      transition: 0.5s;
      height: 30px;

      padding: 6px 10px 6px 10px;
      border-radius: 8px;
      &:focus {
        outline: 1px solid #697586;
        width: 170px;
        border: 1px solid #cdd5df;
        opacity: 1;
      }
    }
  }

  .nav {
    .nav-link {
      cursor: pointer;
      padding: 10px 12px;
      border-radius: 14px;
      position: relative;
      z-index: 999;
      transition: all 0.6s linear;
      &:hover {
        background-color: #029199 ;
        transition: all 0.6s linear;
        .nav-title{
        color : #CCF075;

        }
      }
    }
  }

  .language {
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 12px;
    z-index: 999;
    transition: all 0.6s linear;
    &:hover {
      background-color: #029199
      transition: all 0.6s linear;
      .title {
        color: #fff;
      }
    }
  }

  @media (max-width: 1381px) {
    .nav-title {
      font-size: 15px;
    }
  }
`;

const HeaderMobileContainer = styled.div`
margin-bottom:${(props) =>
  props.path === "/" ||
  props.path?.includes("tip") ||
  props.path?.includes("overview") ||
  props.path?.includes("week-pathway") ||
  props.path?.includes("task-reviews") ||
  props.path?.includes("universities") ||
  props.path?.includes("payment") ||
  props.path?.includes("agents")
    ? "0px"
    : "20px"};
background-color: #fff;
padding : 10px 0px;
position : sticky;
top : 0;
z-index :99;
box-shadow : 0 4px 15px 0 rgba(0, 0, 0, 0.1);
.mantine-Menu-dropdown {
  background-color: #00A3AC;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 14px 14px;
  box-shadow : 0 4px 15px 0 rgba(0,0,0,0.2);
}
.language {
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 12px;
  z-index: 999;
  transition: all 0.6s linear;
  &:hover {
    background-color: #029199
    transition: all 0.6s linear;
    .title {
      color: #fff;
    }
  }
}
.language-dropdown {
  padding: ${(props) =>
    props.selectedLang === "tr" ? "14px 24px" : "14px 28px"} ;
}
.profile{
  height: 51px;
  border-radius: 14px;
  z-index: 99999;
  background : #fff;
  transition : all 0.3s ease-in-out;
   &:hover {
   box-shadow : 0 4px 15px 0 rgba(0,0,0,0.2);
   width : 45%;
   transition : all 0.3s ease-in-out;
   padding : 0 10px;

   }
  }
  .profile-dropdown {
    background-color: #fff !important;
    padding: 12px;
  }
  .search-box {
    display: flex;
    align-items: center;
    position: relative;

    .search-list {
      z-index: 99;
      position: absolute;
      top: 55px;
      left: 0px;
      width: 280px;
      background: #fff;
      border-radius: 8px;
      max-height: 200px;
      padding: 6px 10px;
      border: 1px solid #ccc;
      list-style: none;
      font-size: 12px;
      font-family: "Inter";
      cursor: pointer;

      li {
        padding: 5px;
        border-radius: 5px;
        &:hover {
          background: #eee;
        }
      }
    }

    .icon-item {
      background: none;
      position: absolute;
      right: 10px;
    }

    &:hover {
      .search-input {
        width: 170px;
        border: 1px solid #cdd5df;
        opacity: 1;
      }
    }

    .search-input {
      opacity: 0;
      width: 0px;
      overflow: hidden;
      transition: 0.3s;
      cursor: pointer;
      border: none;
      transition: 0.5s;
      height: 30px;

      padding: 6px 10px 6px 10px;
      border-radius: 8px;
      &:focus {
        outline: 1px solid #697586;
        width: 170px;
        border: 1px solid #cdd5df;
        opacity: 1;
      }
    }
  }
  @media (max-width:390px){

  }
`;
