import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import RouteLogic from "./RouteLogic";
import { AppContext } from "../Helpers/Context";
import { useQuery } from "@tanstack/react-query";
import axios from "../Helpers/Axios";
import Cookies from 'universal-cookie';

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const [gotFields, setGotFields] = useState(false);



  useEffect(() => {
    if(user && user?.required_empty_data?.length > 0) {
      navigate("/profile");
    }
  },[user,location.pathname])


  // useEffect(() => {
  //   if(currentDomain.startsWith('test')){
  //     checkAndCleanCookies();
  //   }
  // }, []); 

  // useEffect(() => {
  //   if(currentDomain === "localhost" && cookieDomain )
  // },[user])

  useQuery(
    ["user-data", gotFields],
    async () => {
      if (gotFields) {
        const httpClient = axios.create();

        return await httpClient
          .post("api/v1/user-data/")
          .then((response) => response.data);
      }
    },
    {
      onSuccess: (data) => {
        localStorage.setItem("taskooluser", JSON.stringify(data));
        window.location = "/home"
      },
      onError: (error) => {},
    },
    {
      enabled: !!gotFields,
    }
  );

  useEffect(() => {
 if (user && !('required_empty_data' in user) && user?.user_type?.type === "Student") {
      setGotFields(true);
    }
  }, [user])



  // useEffect(() => {

  //  if(!user){
  //   setGotFields(true);
  //  }
  // },[user])


  return (
    <Routes>
      <Route path="*" element={<RouteLogic />} />
    </Routes>
  );
};

export default AppContent;
