import { React, useEffect, useState } from "react";
import styled from "styled-components";
import { FirstPart } from "./FirstPart";
import { SecondPart } from "./SecondPart";
import { ThirdPart } from "./ThirdPart";
import { ForthPart } from "./ForthPart";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { useMutation } from '@tanstack/react-query'
import axios from '../../../Helpers/Axios'
import { Helmet } from "react-helmet-async";

const Landing = () => {
  const { t } = useTranslation();

  const cookie = new Cookies()
  const [accessToken, setAccessToken] = useState(cookie.get("access"));
  const [redirect, setRedirect] = useState(false);

  const { mutate: sendToLocal } = useMutation({
    mutationFn: async () => {
      const response = await axios.post("api/v1/user-data/");
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      if (data?.required_empty_data?.length > 0) {
        window.location.href = '/profile';
      } else {
        window.location.href ='/home';
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    accessToken && sendToLocal();
  }, [accessToken]);

  useEffect(() => {
    setAccessToken(cookie.get("access"));
  }, []);

  useEffect(() => {
    redirect && window.location.reload();
  }, [redirect]);

  return (
    <>
      <Helmet>
        <title>{t('landing_title', 'Learn in practice | Taskool')}</title>
        <meta name="description" content={t('landing_description')} />
      </Helmet>
      <LandingStyle props={{ t }}>
        <div id="main-section">
          <FirstPart props={{ t }} />
        </div>
        <div id="about-section">
          <SecondPart props={{ t }} />
        </div>
        <div id="mission-section">
          <ThirdPart props={{ t }} />
        </div>
        <div id="faq-section">
          <ForthPart props={{ t }} />
        </div>
      </LandingStyle>
    </>
  );
};

export default Landing;

const LandingStyle = styled.div`
  overflow: hidden;
`;
